import { Suspense, useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { LayoutProvider, LayoutSplashScreen } from '../_library/layout/core';
import { ModalManager } from '../common/modals/ModalManager';
import AuthInit from './modules/auth/redux/AuthInit';
import { MainRoutes } from './routing/MainRoutes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './styles/overrides.scss';
import { Alert, Button } from 'react-bootstrap';
import { DeleteIcon } from './components/icons/DeleteIcon';
import { ActivityModel } from '../features/activity/interfaces/activity.model';
import { activitySlice } from '../features/activity/activity.api';
import { Warning } from './components/icons/IconList';
import { Icon } from './components/icons/Icon';

export function App() {
  const [show, setShow] = useState(true);
  const [alertsToShow, setAlertsToShow] = useState<ActivityModel[]>([]);
  const { data: alerts, isSuccess: activitiesSuccess, isLoading: activitiesLoading } = activitySlice.usePublicActivitiesQuery()
  useEffect(() => {
    if (activitiesSuccess) {
      setAlertsToShow(alerts);
    }
  }, [alerts, activitiesLoading, activitiesSuccess]);

  const handleSuppressAlert = (index: number) => setAlertsToShow(prevState => prevState.toSpliced(index, 1));

  return (
    <>
      <Suspense fallback={<LayoutSplashScreen />}>
        <BrowserRouter>
          <div className="position-absolute top-0 z-index-3 w-100">
            { alertsToShow?.map((activity, idx)=> {
                return(
                  <Alert key={idx}
                         show={show}
                         variant="danger"
                         className="py-0 my-0 w-100">
                    <div className="d-flex justify-content-between align-middle">
                      <div>
                        <Icon classes="text-danger d-inline-block"
                          type={Warning.iconType}
                          size="sm"
                          icon={Warning}
                        />
                        <p className="text-center p-0 m-2 fw-bolder d-inline-block">
                          <i className="bi bi-warning-fill fs-7"></i>
                          {activity.message}
                        </p>
                      </div>
                      <button
                        className="btn btn-sm d-inline p-0 bg-hover-light-danger text-hover-danger ms-auto align-middle"
                        onClick={() => handleSuppressAlert(idx)}
                      >
                        <DeleteIcon/>
                      </button>
                    </div>
                  </Alert>
                );
              })

            }
          </div>
          {!show && <Button onClick={() => setShow(true)}>Show Alert</Button>}
          <ToastContainer />
          <ModalManager />
          <LayoutProvider>
            <AuthInit>
              <MainRoutes />
            </AuthInit>
          </LayoutProvider>
        </BrowserRouter>
      </Suspense>
    </>
  )
}
