import axios from 'axios';
import { format } from 'date-fns';
import { useFormik } from 'formik';
import { ChangeEvent, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import DatePicker from 'react-datepicker';
import { shallowEqual, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import invariant from 'tiny-invariant';
import * as Yup from 'yup';
import { toAbsoluteUrl } from '../../../_library/helpers';
import { PhoneNumberInput } from '../../../app/components/PhoneNumberInput';
import { deleteTeamMember, updateEmployee, UPLOAD_EMPLOYEE_AVATAR_URL } from '../../../app/CRUD/TeamCRUD';
import { DateFormatUsingDateFns } from '../../../app/modules/date/DateFormat.const';
import { parseYmdDatesToDateObject } from '../../../app/modules/date/utils/DateHelpers';
import { EmployeeType } from '../../../app/types/EmployeeType';
import { RootState } from '../../../setup';
import { EmployeeDeleteModal } from './EmployeeDeleteModal';

interface Props {
	employee: EmployeeType;
	show?: boolean;
	onClose?: (action: boolean) => void;
	refreshEmployeeData: () => void;
}

export function EmployeeEditModal({
																		employee,
																		show,
																		onClose,
																		refreshEmployeeData,
																	}: Props) {
	const token: string = useSelector<RootState>(({auth}) => auth.accessToken, shallowEqual) as string;
	const [showModal, setShowModal] = useState(show);
	const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
	const [selectedAvatarUrl, setSelectedAvatarUrl] = useState<any>('');
	const [avatarFile, setAvatarFile] = useState<any>();
	const [loading, setLoading] = useState(false);
	const [birthDate, setBirthDate] = useState<Date | null>(!!employee.birthDate ? parseYmdDatesToDateObject(employee.birthDate) : null);
	const [hireDate, setHireDate] = useState<Date | null>(!!employee.hireDate ? parseYmdDatesToDateObject(employee.hireDate) : null);
	const [terminatedDate, setTerminatedDate] = useState<Date | null>(!!employee.terminatedDate ? parseYmdDatesToDateObject(employee.terminatedDate) : null);
	const [employmentStatus, setEmploymentStatus] = useState<string>(employee.isTerminated ? 'Terminated' : 'Active');
	const [agency, setAgency] = useState<string>(employee.isAgency ? 'yes' : 'no');
	const [fmla_return_date, setFmlaEndDate] = useState<Date | null>(!!employee.fmla_return_date ? parseYmdDatesToDateObject(employee.fmla_return_date) : null);
	const navigate = useNavigate();

	const initialValues: EmployeeType = {
		id: employee.id,
		firstName: employee.firstName,
		lastName: employee.lastName,
		birthDate: employee.birthDate,
		hireDate: employee.hireDate,
		employeeId: employee.employeeId,
		phone: employee.phone,
		isAgency: employee.isAgency,
		isTerminated: employee.isTerminated,
		currentAttendancePoints: employee.currentAttendancePoints,
		currentPerformancePoints: employee.currentPerformancePoints,
		currentPoints: employee.currentPoints,
		avatarUrl: '',
		fmla_return_date: employee.fmla_return_date,
		fmla_hours_left: employee.fmla_hours_left,
		terminatedDate: employee.terminatedDate,
		recognitionLast30: 0,
		recognitionLastYear: 0,
		misc: employee.misc || {details: {notes: ''}},
	};
	const profileDetailsSchema = Yup.object().shape({
		firstName: Yup.string().required('Full name is required'),
		lastName: Yup.string().required('Last name is required'),
		employeeId: Yup.string().required('Employee ID is required'),
		birthDate: Yup.string().required('Birthday is required'),
		hireDate: Yup.string().required('Hire Date is required'),
		phone: Yup.string().optional().nullable(),
	});
	const formik = useFormik<EmployeeType>({
		initialValues,
		validationSchema: profileDetailsSchema,
		validateOnMount: true,
		onSubmit: (values,
							 {
								 setStatus,
								 setSubmitting,
							 }) => {
			setLoading(true);
			setTimeout(() => {
				invariant(birthDate, 'Unable to update employee as no birthday is selected');
				invariant(hireDate, 'Unable to update employee as no hireDate is selected');
				const employeeData = {
					firstName: values.firstName,
					lastName: values.lastName,
					birthDate: format(birthDate, DateFormatUsingDateFns.PerfectDate),
					hireDate: format(hireDate, DateFormatUsingDateFns.PerfectDate),
					employeeId: values.employeeId,
					phone: values.phone,
					isAgency: agency === 'yes',
					isTerminated: employmentStatus === 'Terminated',
					terminatedDate: terminatedDate,
					misc: values.misc,
					fmla_return_date: fmla_return_date ? format(fmla_return_date, DateFormatUsingDateFns.PerfectDate) : null,
					fmla_hours_left: values.fmla_hours_left,
				};
				updateEmployee(employee.id, employeeData, token)
					.then(({data}) => {
						if (avatarFile) {
							const employeeId = data.id;
							// avatar upload
							const requestBody = new FormData();
							requestBody.append('file', avatarFile);
							axios.post(`${UPLOAD_EMPLOYEE_AVATAR_URL}/${employeeId}/avatar`, requestBody,
										 {
											 headers: {'Content-Type': 'multipart/form-data'},
										 },
									 )
									 .then(() => {
										 refreshEmployeeData();
										 setLoading(false);
										 setShowModal(false);
										 // window.location.reload()
									 })
									 .catch((err) => {
										 console.log(err);
										 refreshEmployeeData();
										 setLoading(false);
										 setSubmitting(false);
										 setStatus(err.response.data.message);
									 });
						} else {
							refreshEmployeeData();
							setLoading(false);
							setShowModal(false);
							// window.location.reload()
						}
					})
					.catch((err) => {
						console.log(err);
						setLoading(false);
						setSubmitting(false);
						setStatus(err.response.data.message);
					});
			}, 1000);
		},
	});

	useEffect(() => {
		console.log('Do we show or not?');
		setShowModal(show);
	}, [show]);


	const handleClose = () => {
		// setShowModal(false);
		if (onClose) {
			onClose(false);
		}
	};

	const handleOnSubmit = () => {
		if (onClose) {
			onClose(false);
		}
		formik.submitForm();
	};

	const handleDeleteClose = () => {
		setShowDeleteModal(false);
	};

	const handleDeleteConfirm = () => {
		deleteTeamMember(employee.id, token).then((res) => {
			if (res.data) {
				toast.success(`Successfully removed ${employee.firstName} ${employee.lastName}!`, {
					autoClose: 2500,
				});
				if (employee.teams) {
					navigate(`/teams/show/${employee.teams[0].id}/overview`);
				}
			} else {
				toast.error(`Removing employee ${employee.firstName} ${employee.lastName} failed!`);
			}

		});
		setShowDeleteModal(false);
	};

	const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
		if (e.target.files && e.target.files[0]) {
			let reader = new FileReader();
			let file = e.target.files[0];
			setAvatarFile(file);
			reader.onloadend = () => {
				if (reader && reader.result) {
					setSelectedAvatarUrl(reader.result);
				}
			};
			reader.readAsDataURL(file);
		}
	};

	const removeAvatar = () => {
		setAvatarFile(null);
		setSelectedAvatarUrl('');
	};


	return (
		<>
			<Modal
				show={showModal}
				onHide={handleClose}
				backdrop="static"
			>
				<Modal.Header closeButton>
					<Modal.Title>Edit Team Member</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form
						onSubmit={() => console.log('Submit to my command!')}
					>
						{formik.status && (
							<div className="mb-lg-15 alert alert-danger">
								<div className="alert-text font-weight-bold">{formik.status}</div>
							</div>
						)}

						<div className="row mb-6">
							<label className="col-lg-4 col-form-label fw-bold fs-6">Avatar</label>
							<div className="col-lg-12">
								<div
									className={`image-input image-input-outline w-120px h-120px ${!selectedAvatarUrl ? 'image-input-empty' : ''}`}
									data-kt-image-input="true"
									style={{backgroundImage: `url(${employee.avatarUrl ? employee.avatarUrl : toAbsoluteUrl('/media/avatars/blank.png')})`}}
								>
									<div
										className="image-input-wrapper w-125px h-125px"
										style={{backgroundImage: `url(${toAbsoluteUrl(selectedAvatarUrl)})`}}
									></div>
									<label
										className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
										data-kt-image-input-action="change"
										data-bs-toggle="tooltip"
										title=""
										data-bs-original-title="Change avatar">
										<i className="bi bi-pencil-fill fs-7"></i>
										<input
											type="file"
											name="avatar"
											accept=".png, .jpg, .jpeg"
											onChange={handleFileChange} />
										<input
											type="hidden"
											name="avatar_remove" />
									</label>
									<span
										className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
										data-kt-image-input-action="remove"
										data-bs-toggle="tooltip"
										title=""
										data-bs-original-title="Remove avatar"
										onClick={removeAvatar}>
														<i className="bi bi-x fs-2"></i>
												</span>
								</div>
								<div className="form-text">Allowed file types: png, jpg, jpeg.</div>
							</div>
						</div>

						<div className="row mb-6">
							<div className="col-lg-6">
								<label className="col-form-label required fw-bold fs-6">First Name</label>

								<div className="col-lg-12">
									<input
										type="text"
										className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
										placeholder=""
										{...formik.getFieldProps('firstName')}
									/>
									{formik.touched.firstName && formik.errors.firstName && (
										<div className="fv-plugins-message-container">
											<div className="fv-help-block">{formik.errors.firstName}</div>
										</div>
									)}
								</div>
							</div>
							<div className="col-lg-6">
								<label className="col-form-label required fw-bold fs-6">Last Name</label>

								<div className="col-lg-12">
									<input
										type="text"
										className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
										placeholder=""
										{...formik.getFieldProps('lastName')}
									/>
									{formik.touched.lastName && formik.errors.lastName && (
										<div className="fv-plugins-message-container">
											<div className="fv-help-block">{formik.errors.lastName}</div>
										</div>
									)}
								</div>
							</div>
						</div>

						<div className="row mb-6">
							<div className="col-lg-6">
								<label className="col-form-label required fw-bold fs-6">Employee ID</label>

								<div className="col-lg-12 fv-row">
									<input
										type="text"
										className="form-control form-control-lg form-control-solid"
										placeholder="Employee Id"
										{...formik.getFieldProps('employeeId')}
									/>
									{formik.touched.employeeId && formik.errors.employeeId && (
										<div className="fv-plugins-message-container">
											<div className="fv-help-block">{formik.errors.employeeId}</div>
										</div>
									)}
								</div>
							</div>
							<div className="col-lg-6">
								<label className="col-form-label required fw-bold fs-6">Temp/Agency</label>
								<div className="col-lg-12 fv-row">
									<select
										className="form-select form-select-solid form-select-lg"
										onChange={
											(e) => {
												setAgency(e.target.value);
											}
										}
										value={agency}
									>
										<option value="yes">Yes</option>
										<option value="no">No</option>
									</select>
								</div>
							</div>
						</div>

						<div className="row mb-6">
							<div className="col-lg-6">
								<label className="col-form-label required fw-bold fs-6">Birthday</label>

								<div className="col-lg-12 fv-row">
									<DatePicker
										selected={birthDate}
										onChange={(date) => {
											setBirthDate(date);
											if (date) {
												formik.setFieldValue('birthDate', format(date, DateFormatUsingDateFns.PerfectDate));
											}
										}}
										className="form-control form-control-lg form-control-solid" />
								</div>
							</div>
							<div className="col-lg-6">
								<PhoneNumberInput
									label="Phone"
									phoneNumber={formik.values.phone}
									formik={formik}
									setPhoneNumber={phone => formik.setFieldValue('phone', phone, true)}
									error={(formik.touched.phone && formik.errors.phone) ? formik.errors.phone : ''} />
							</div>
						</div>

						<div className="row mb-6">
							<div className="col-lg-6">
								<label className="col-form-label required fw-bold fs-6">Hire Date</label>

								<div className="col-lg-12 fv-row">
									<DatePicker
										selected={hireDate}
										onChange={(date) => {
											setHireDate(date);
											if (date) {
												formik.setFieldValue('hireDate', format(date, DateFormatUsingDateFns.PerfectDate));
											}
										}}
										className="form-control form-control-lg form-control-solid" />
								</div>
							</div>

							<div className="col-lg-6">
								<label className="col-form-label required fw-bold fs-6">Employment Status</label>
								<div className="col-lg-12 fv-row">
									<select
										className="form-select form-select-solid form-select-lg"
										onChange={
											(e) => {
												setEmploymentStatus(e.target.value);
											}
										}
										value={employmentStatus}
									>
										<option value="Active">Active</option>
										<option value="Terminated">Terminated</option>
									</select>
									{/* {formik.touched.employmentStatus && formik.errors.employmentStatus && (
									 <div className='fv-plugins-message-container'>
									 <div className='fv-help-block'>{formik.errors.employmentStatus}</div>
									 </div>
									 )} */}
								</div>
							</div>
						</div>

						<div className="row mb-6">
							<div className="col-lg-6">
								<label className="col-form-label fw-bold fs-6">FMLA End Date</label>
								<div className="col-lg-12 fv-row">
									<DatePicker
										selected={fmla_return_date}
										onChange={(date) => setFmlaEndDate(date)}
										className="form-control form-control-lg form-control-solid" />
								</div>
							</div>

							<div className="col-lg-6">
								<label className="col-form-label fw-bold fs-6">FMLA Hours Left</label>
								<div className="col-lg-12">
									<input
										type="number"
										min="0"
										className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
										placeholder=""
										{...formik.getFieldProps('fmla_hours_left')}
									/>
									{formik.touched.fmla_hours_left && formik.errors.fmla_hours_left && (
										<div className="fv-plugins-message-container">
											<div className="fv-help-block">{formik.errors.fmla_hours_left}</div>
										</div>
									)}
								</div>
							</div>
						</div>

						<div className="row mb-6">
							<div className="col-lg-12">
								<label className="col-form-label required fw-bold fs-6">Employee Notes</label>
								<div className="col-lg-12 fv-row">
											<textarea
												id="notes"
												className="form-control form-control-lg form-control-solid"
												{...formik.getFieldProps('misc.details.notes')}
											/>
								</div>
							</div>
						</div>

						<div className="row mb-6">
							<div className="col-lg-6">
								{
									employmentStatus === 'Terminated' && (
										<div>
											<label className="col-form-label fw-bold fs-6">Terminated Date</label>
											<div className="col-lg-12 fv-row">
												<DatePicker
													// disabled={true}
													selected={terminatedDate}
													onChange={(date) => {
														setTerminatedDate(date);
													}}
													className="form-control form-control-lg form-control-solid"
												/>
											</div>
										</div>
									)}
							</div>
						</div>

					</form>
				</Modal.Body>
				<Modal.Footer>
					{/*<Button variant="secondary" onClick={handleClose}>*/}
					{/*	Close*/}
					{/*</Button>*/}
					{/*<Button variant="primary">Understood</Button>*/}
					<div
						className="d-flex justify-content-between w-100"
					>
						<Button
							className="btn btn-danger "
							onClick={() => setShowDeleteModal(true)}
						>
							Remove Member
						</Button>
						<Button
							onClick={handleOnSubmit}
							className="btn btn-primary"
						>
							{!loading && 'Update User'}
							{loading && (
								<span
									className="indicator-progress"
									style={{display: 'block'}}>
														Please wait...{' '}
									<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
												</span>
							)}
						</Button>
						<EmployeeDeleteModal
							employee={employee}
							show={showDeleteModal}
							onClose={handleDeleteClose}
							onConfirm={handleDeleteConfirm}
						/>
					</div>
				</Modal.Footer>
			</Modal>
		</>
	);
}

