import axios, { AxiosResponse } from 'axios';
import {
	GridFilterItem,
	GridSortItem,
	ResponseWithManyPaginated,
	SkeBaseResponse, SkeResponseWithManyPaginatedResults,
} from '../../common/interfaces/response-format.model';
import {
	ConnectionFilterableFields,
	ConnectionSortableFields,
	Connection,
} from './interfaces/connection.model';

const API_URL = process.env.REACT_APP_API_URL;
export const CONNECTIONS_URL = `${API_URL}/connections`;

export interface GetConnectionPayload {
	page: number;
	pageSize: number;
	fromDate?: string;
	toDate?: string;
	sort?: GridSortItem<ConnectionSortableFields>[];
	filters?: GridFilterItem<ConnectionFilterableFields>[];
}

export function getConnections(token: string,
															 opts: GetConnectionPayload): Promise<AxiosResponse<SkeResponseWithManyPaginatedResults<Connection>>> {
	// post<T = any, R = axios.AxiosResponse<T>, D = any>(url: string, data?: D, config?: axios.AxiosRequestConfig<D>): Promise<R>;
	return axios.post<SkeResponseWithManyPaginatedResults<Connection>>(CONNECTIONS_URL, opts,
		{
			headers: {
				'Authorization': `Bearer ${token}`,
			},
		});
}
