import { Company } from '../../../app/modules/company/models/Company.model';
import { TemplateModel } from '../../../app/modules/performance/models/PerformanceCategory.models';

export interface CategoryModel {
	id: number;
	tenant_id: string;
	// TODO: CompanyGroup/ABAC will be string
	group_id: null;
	type: CategoryTypeEnum;
	subtype: CategorySubtypeEnum
	company?: Company;
	name: string;
	// TODO: not yet implemented
	metadata?: unknown;
	updated_at: string;
	created_at: string;
	deleted_at: string | null;
	templates: TemplateModel[];
}

export enum CategoryTypeEnum {
	Discipline = 'DISCIPLINE',
	Coaching = 'COACHING',
	Attendance = 'ATTENDANCE',
	Recognition = 'RECOGNITION',
}

export enum CategorySubtypeEnum {
	// for CategoryTypeEnum.Discipline
	Attendance = 'ATTENDANCE',
	// for CategoryTypeEnum.Discipline
	Performance = 'PERFORMANCE',
}
