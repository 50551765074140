import clsx from 'clsx';
import { ReactNode, useState } from 'react';
import _ from 'lodash';
import { Icon } from '../../../components/icons/Icon';
import { CaretRight, CaretUp } from '../../../components/icons/IconList';

export interface Props {
	title?: string;
	// using children will override use of items
	children?: ReactNode[];
	items?: SimpleExpandableListItem[];
	badgeContent?: string | number;
	badgeClasses?: string;
	// setting to 0 will hide the show more if isHeaderCollapsible is falsy
	collapsedLength?: number;
	maxLength?: number;
	isHeaderCollapsible?: boolean;
	onClick?: (item: any) => void;
}

export interface SimpleExpandableListItem {
	title: string;
	content: string;
}

export function SkeSimpleExpandableList({
																					title,
																					children,
																					collapsedLength = 5,
																					maxLength,
																					items,
																					badgeContent,
																					badgeClasses,
																					isHeaderCollapsible = false,
																					onClick,
																				}: Props) {
	const [isExpanded, setIsExpanded] = useState<boolean>(false);
	const getHiddenLength = () => {
		if (children) {
			if (maxLength && maxLength < children.length) {
				return  `(${maxLength - collapsedLength})`
			}
			return `(${children.length - collapsedLength})`;
		}
		if (items?.length) {
			if (maxLength && maxLength < items.length) {
				return  `(${maxLength - collapsedLength})`
			}
			return `(${items.length - collapsedLength})`;
		}
		return '';
	}

	return (
		<div className="skeSimpleExpandableList">
			{title && (
				<>
				<h3
					className={clsx('d-inline-block', {
						'cursor-pointer': isHeaderCollapsible,
					})}
					onClick={() => !!isHeaderCollapsible && setIsExpanded(!isExpanded)}>
						{title}
						{!!badgeContent && (
							<span
								className={clsx('d-inline-block ms-2', badgeClasses)}>{badgeContent}</span>
						)}

						{isHeaderCollapsible && (
							<>
								{!isExpanded && (
									<Icon
										type="svg"
										size="md"
										icon={CaretRight}
										classes="ms-1 align-self-center"
										alt="expand list"
										use="functional"></Icon>
								)}
								{isExpanded && (
									<Icon
										type="svg"
										size="md"
										icon={CaretUp}
										classes="ms-1 align-self-center"
										alt="collapse list"
										use="functional"></Icon>
								)}
							</>
						)}
					</h3>
				</>
			)}
			{children?.length === 0 && <p>No items</p>}
			<ul
				aria-expanded={isExpanded}
				className="list-unstyled">
				{!!children?.length && (
					<>
						{
							children
								.slice(0, isExpanded ? maxLength || children.length : collapsedLength)
						}
					</>
				)}
				{!children?.length && !!items?.length && (
					<>
						{
							items
								.slice(0, isExpanded ? maxLength || items.length : collapsedLength)
								.map((item, idx) => {
									return (
										<li
											className="mb-1"
											key={idx}>
											<span className="fw-bold">{item.title}: </span>
											{item.content}
										</li>
									);
								})
						}
					</>
				)}
				{
					(!!children && children?.length > collapsedLength
						|| items && items?.length > collapsedLength
						|| collapsedLength === 0)
					&& !isHeaderCollapsible
					&& (
					<button
						className="btn btn-link btn-outline-secondary bg-transparent btn-active-text-muted"
						onClick={() => setIsExpanded(!isExpanded)}>
						Show {isExpanded ? 'Less' : `More ${getHiddenLength()}`}
					</button>
				)}
			</ul>
		</div>
	);
}
