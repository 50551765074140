import {ReactNode, useEffect, useState} from 'react';
import {Button, Modal} from 'react-bootstrap';

interface Props {
	title: string;
	show?: boolean;
	onClose: (action: boolean)=> void;
	onSubmit: ()=> void;
	children?: ReactNode;
}

export function SkeModal({
														title,
														show,
														onClose,
														onSubmit,
														children,
												 }: Props){
	const [showModal, setShowModal] = useState(show);

	const handleClose = ()=>{
		if (onClose) {
			onClose(false);
		}
	}

	const handleOnSubmit = () => {
		if (onSubmit) {
			onSubmit();
		}
	};

	useEffect(() => {
		setShowModal(show);
	}, [show]);

	return(
		<>
			<Modal
				show={showModal}
				onHide={handleClose}
				backdrop="static"
			>
				<Modal.Header closeButton>
					<Modal.Title>{title}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{children}
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						Cancel
					</Button>
					<Button variant="primary" onClick={handleOnSubmit}>
						Save Changes
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);

}