import clsx from 'clsx';
import { concat, get } from 'lodash';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { Accordion, Form, Spinner } from 'react-bootstrap';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import XLSX from 'xlsx';
import { FileDropzone } from '../../../../../../common/components/file-dropzone';
import { companySlice } from '../../../../../../features/company/company.api';
import {
	BatchProcessIssue,
	EmployeeUploadApiResponse,
	TeamReconcileIssue,
} from '../../../../../../features/company/interfaces/company.model';
import { teamSlice, TeamTagTypes } from '../../../../../../features/team/team.api';
import { RootState } from '../../../../../../setup';
import { UserModel } from '../../../../../models';
import { SkeSimpleExpandableList } from '../../../../../modules/shared/components/SimpleExpandableList';
import { SkeTooltip } from '../../../../../modules/shared/components/Tooltip';
import { IMPORT_PROCESSES_DETAILS, ImportProcesses } from '../constants/imports.models';

export function EmployeeUpload() {
	const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel;
	const [selectedProcess, setSelectedProcess] = useState<ImportProcesses>();
	const [importCsv, uploadState] = companySlice.useRunProcessMutation();
	const [fileToUpload, setFileToUpload] = useState<File[]>([]);
	const [createTeams, setCreateTeams] = useState<boolean>(false);
	const [assignTeams, setAssignTeams] = useState<boolean>(false);
	const [reassignTeams, setReassignTeams] = useState<boolean>(false);
	const [optInNewEmployeesToTexting, setOptInNewEmployeesToTexting] = useState<boolean>(false);
	const [error, setError] = useState<string>();
	const dispatch = useDispatch();
	const [expandedInstructions, setExpandedInstructions] = useState<boolean>(true);
	const [uploadResults, setUploadResults] = useState<EmployeeUploadApiResponse>();
	const [uploadIssues, setUploadIssues] = useState<BatchProcessIssue<TeamReconcileIssue>[]>([]);

	const handleUploadFile = () => {
		if (!user.company?.id) return console.error('No tenant_id found, unable to proceed');
		if (!selectedProcess) return console.error('No process selected, unable to proceed');

		importCsv({
			tenant_id: user.company.id,
			processType: selectedProcess,
			spreadsheetFiles: fileToUpload,
			createTeams,
			assignTeams,
			reassignTeams,
			optInNewEmployeesToTexting,
		}).then(function(res: any) {
			if (res.error) {
				setError(res.error.msg);
				return toast.error('Error processing file');
			}
			if (res?.data?.success) {
				setUploadResults(res.data);
				const issues: BatchProcessIssue<TeamReconcileIssue>[] = concat([],
					get(res, 'data.results.issues', []),
					get(res, 'data.results.employeesCreated.issues', []),
					get(res, 'data.results.employeesUpdated.issues', []),
					get(res, 'data.results.employeesAssigned.issues', []),
					get(res, 'data.results.employeesReassigned.issues', []),
					get(res, 'data.results.teamsCreated.issues', []),
				);
				setUploadIssues(issues);
				dispatch(teamSlice.util?.invalidateTags([
					TeamTagTypes.Teams,
					TeamTagTypes.Employees,
				]));
			}
		});
	};

	const handleDownloadTemplate = () => {
		const sheet = XLSX.utils.json_to_sheet([], {
			header: [
				'First Name',
				'Last Name',
				'Employee ID',
				'Email',
				'Phone',
				'Hire Date',
				'Terminated Date',
				'Birth Date',
				'Team Name',
			],
		});
		const newWorkbook = XLSX.utils.book_new(sheet);
		XLSX.writeFileXLSX(newWorkbook, 'Secchi Employee Upload Template.xlsx')
	};

	useEffect(() => {
		if (uploadState.isSuccess) {
			setUploadResults(uploadState.data);
		}
	}, [uploadState]);

	useEffect(() => {
		setUploadResults(undefined);
	}, []);

	return (
		<div className="card mb-5 mb-xl-10">
			<div
				className="card-header border-0 cursor-pointer"
				role="button"
				data-bs-toggle="collapse"
				data-bs-target="#kt_account_profile_details"
				aria-expanded="true"
				aria-controls="kt_account_profile_details"
			>
				<div className="card-title m-0">
					<h3 className="fw-bolder m-0">Imports</h3>
				</div>
			</div>

			<div
				id="kt_account_profile_details"
				className="collapse show">
				<div className="card-body border-top p-9">
					<div className="row mb-6">
						<label className="col-lg-4 col-form-label fw-bold fs-6">
							<span className="required">Import Type</span>
						</label>
						<div className="col-lg-8 fv-row">
							<select
								className="form-select form-select-solid form-select-lg fw-bold"
								onChange={(e: ChangeEvent<HTMLSelectElement>) => {
									setSelectedProcess((e.target.value as ImportProcesses));
								}}
							>
								<option value="">Select a Process...</option>
								{
									Object.values(ImportProcesses).map((process, i) => {
										return <option
											key={i}
											value={process}>{IMPORT_PROCESSES_DETAILS[process].name}</option>;
									})
								}
							</select>
							{!!selectedProcess && IMPORT_PROCESSES_DETAILS[selectedProcess].description && (
								<div className="py-3">
									<span><span className="fw-bolder">Description: </span>{IMPORT_PROCESSES_DETAILS[selectedProcess].description}</span>
									<div className="py-2">
										<button
											className="btn btn-primary"
											onClick={handleDownloadTemplate}>Download template
										</button>
									</div>
									<Accordion
										className="mt-3"
										onClick={() => setExpandedInstructions(!expandedInstructions)}
										activeKey={expandedInstructions ? '0' : '1'}>
										<Accordion.Item eventKey="0">
											<Accordion.Header className="border border-1 border-info"><span className="fw-bold">Import Info</span></Accordion.Header>
											<Accordion.Body className="border border-1 border-info border-top-0">
												<ul className="list-group">
													<li className="list-group-item">
														<span className="fw-bolder">First Name:</span> Required
													</li>
													<li className="list-group-item">
														<span className="fw-bolder">Last Name:</span> Required
													</li>
													<li className="list-group-item">
														<span className="fw-bolder">Employee ID:</span> Required
													</li>
													{/*<li className="list-group-item">
													 <span className="fw-bold">Email:</span> Optional
													 </li>*/}
													<li className="list-group-item">
														<span className="d-block">
															<span className="fw-bolder">Phone:</span> Optional
														</span>
														<span className="d-block">
															This is only used if an existing employee has already opted in, or for new employees if you select <span className="fw-bold">Sign up new employees for texting</span> below.
														</span>
													</li>
													<li className="list-group-item">
														<span className="d-block">
															<span className="fw-bolder">Hire Date</span> Optional (example date January 2nd, 2024)
														</span>
														<span className="d-block">
															Supported formats: 1/2/24, 1/2/2024, 01/02/2024, 2024-01-02
														</span>
													</li>
													<li className="list-group-item">
														<span className="d-block">
															<span className="fw-bolder">Birth Date</span> Optional (example date January 2nd, 2000)
														</span>
														<span className="d-block">
															<span className="fst-italic">We don't store the year of birth dates, so you can provide just month and day for privacy</span>
															<span className="d-block">
																Supported formats: 1/2, 01/02, 1/2/00, 1/2/2000, 01/02/2000, 2000-01-02
															</span>
														</span>
													</li>
													<li className="list-group-item">
														<span className="d-block">
															<span className="fw-bolder">Terminated Date</span> Optional (example date January 2nd, 2024)
														</span>
														<span className="d-block fst-italic">Assumes not terminated if no value entered in this field</span>
														<span className="d-block">
															Supported formats: 1/2/24, 1/2/2024, 01/02/2024, 2024-01-02
														</span>
													</li>
													<li className="list-group-item">
														<span className="fw-bolder">Team Name</span> Optional
														<span className="d-block">Can provide one more multiple team names (separated by commas)</span>
													</li>
												</ul>
											</Accordion.Body>
										</Accordion.Item>
									</Accordion>
								</div>
							)
							}
							{
								selectedProcess === ImportProcesses.EmployeeUpload && (
									<>
										<FileDropzone
											title="Drag and drop employee roster here"
											onFileUploaded={(files) => {
												setFileToUpload(files);
											}} />
										{!!fileToUpload.length && (
											<>
												<h5>Options</h5>
												<div className="border-top-1 border-bottom-1">
													<Form.Check
														type="switch"
													>
														<Form.Check.Input
															checked={optInNewEmployeesToTexting}
															onChange={e => setOptInNewEmployeesToTexting(e.target.checked)}
															type="checkbox" />
														<Form.Check.Label>Sign up new employees for texting</Form.Check.Label>
														<Form.Text
															className="d-block"
															muted>
															Employees are assumed to not have opted-in to receiving texts. Toggle this so when you
															create new employees, they all get their phone entered and are set up to receive text
															messages. Without this checked, a user would need to manually enter their phone on the
															employee's profile.
														</Form.Text>
													</Form.Check>
												</div>
												<h5>Advanced Options</h5>
												<h6>Team Handling</h6>
												<span className="fw-bold py-2">
													These options are best used for onboarding new teams or locations.
												</span>
												<div className="py-2">
													<Form.Check
														type="switch"
													>
														<Form.Check.Input
															checked={createTeams}
															onChange={e => setCreateTeams(e.target.checked)}
															type="checkbox" />
														<Form.Check.Label>Create Teams</Form.Check.Label>
														<Form.Text
															className="d-block"
															muted>
															Add a "Team Name" column to create a team if one with that name doesn't exist. Creates a
															team for each unique value in this column. Best used with the assign/reassign options below.
														</Form.Text>
													</Form.Check>
													<Form.Check
														type="switch"
													>
														<Form.Check.Input
															checked={assignTeams}
															onChange={e => setAssignTeams(e.target.checked)}
															type="checkbox" />
														<Form.Check.Label>Assign new employees to teams</Form.Check.Label>
														<Form.Text
															className="d-block"
															muted>When creating a new employee (uses badge number/employee ID to verify they're not in
																		the system), if you have a "Team Name" value, they will be assigned to that team
																		automatically. This option does not update team assignments of employees already in
																		the system.</Form.Text>
													</Form.Check>
													<Form.Check
														type="switch"
													>
														<Form.Check.Input
															checked={reassignTeams}
															onChange={e => setReassignTeams(e.target.checked)}
															type="checkbox" />
														<Form.Check.Label>Reassign existing employees to teams</Form.Check.Label>
														<Form.Text
															className="d-block"
															muted>When updating employees (uses badge number/employee ID to verify they're in the
																		system), if you have a "Team Name" value, they will be assigned to that team
																		automatically. This options does not assign new employees that are not in the system.
															<span className="text-danger">
																This will replace any existing team assignments, including removing them from all teams if no value is set in this column when this option is selected.
															</span>
														</Form.Text>
													</Form.Check>
												</div>
											</>
										)}

									</>
								)
							}
							{!!error && (
								<span className="text-danger">{error}</span>
							)}
							{uploadState.isSuccess && !!uploadResults && (
								<>
									<SkeTooltip
										targetElementId="employee-upload-info"
										message="This column acts as confirmation of how many records were processed without known issues.">
									</SkeTooltip>
									<SkeTooltip
										targetElementId="employee-upload-issues"
										message="This confirmation shows how many issues were detected in processing records e.g. incomplete phone number provided.">
									</SkeTooltip>
									<div className="pt-5">
										<table className="table table-bordered border-1">
											<thead>
												<tr>
													<th
														colSpan={5}
														className="text-center text-uppercase fw-bolder"
													>Results
													</th>
												</tr>
												<tr>
													<th className="text-center fw-bold">Area</th>
													<th className="text-center fw-bold">Affected Records</th>
													<th className="text-center fw-bold">Teams Changed</th>
													<th className="text-center fw-bold">Info</th>
													<th className="text-center fw-bold">Issues</th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td>New Employees</td>
													<td>{uploadResults.results.employeesCreated.count} Created</td>
													<td>{uploadResults.results.employeesAssigned.count} Assigned</td>
													<td>{uploadResults.results.employeesCreated.info.length}</td>
													<td>{uploadResults.results.employeesCreated.issues.length}</td>
												</tr>
												<tr>
													<td>Existing Employees</td>
													<td>{uploadResults.results.employeesUpdated.count} Updated</td>
													<td>{uploadResults.results.employeesReassigned.count} Reassigned</td>
													<td>{uploadResults.results.employeesUpdated.info.length}</td>
													<td>{uploadResults.results.employeesUpdated.issues.length}</td>
												</tr>
												<tr>
													<td>New Teams</td>
													<td>{uploadResults.results.teamsCreated.count} Created</td>
													<td>N/A</td>
													<td>{uploadResults.results.teamsCreated.info.length}</td>
													<td>{uploadResults.results.teamsCreated.issues.length}</td>
												</tr>
											</tbody>
										</table>
										<SkeSimpleExpandableList
											title="Issues"
											badgeContent={get(uploadResults, 'results.issues', []).length}
											badgeClasses="badge rounded-pill bg-danger"
											collapsedLength={5}
										>
											{uploadIssues.map(itm => {
												let title: string = '';
												switch (itm.type) {
													case TeamReconcileIssue.InvalidProperty:
														title = 'Invalid data';
														break;
													case TeamReconcileIssue.NoTeamColumn:
														title = 'No Team Column';
														break;
													case TeamReconcileIssue.TeamMissing:
														title = `Missing Team`;
														break;
												}
												return (
													<>
														<div className="d-block mb-2 bg-danger-subtle rounded-1 p-2">
															<span className="fw-bold me-2">{title}:</span>
															<span className="font-monospace">{itm.msg}
															</span>
														</div>
													</>
												);
											})}
										</SkeSimpleExpandableList>
									</div>
								</>
							)}
							<button
								className={clsx('btn btn-primary mt-2',
									{
										disabled: !fileToUpload.length || uploadState.isLoading,
									},
								)}
								onClick={() => handleUploadFile()}
							>
								{!uploadState.isLoading && 'Upload'}
								{uploadState.isLoading && (
									<>
										Uploading
										<Spinner
											size="sm"
											className="ms-2" />
									</>
								)}
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
