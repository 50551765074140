import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../../../setup'
import { KTSVG } from '../../../_library/helpers'
import { DropdownConnection } from '../../components/dropdown/DropdownConnection'
import { getPagination } from '../../CRUD/TeamCRUD'
import { DisciplineStatusForHumansEnum } from '../../modules/discipline/models/Discipline.models';
import { AttendanceType } from '../../types/AttendanceType'
import { CoachingType } from '../../types/CoachingType'
import { RecognitionType } from '../../types/RecognitionType'
import { DisciplineType } from '../../types/DisciplineType'
import './RecentConnectionWidget.scss'

type Props = {
    className: string
}

const RecentConnectionsWidget: React.FC<Props> = ({ className }) => {
    const token: string = useSelector<RootState>(({ auth }) => auth.accessToken, shallowEqual) as string
    const [type, setType] = useState<string>('Attendance')
    const [attendances, setAttendances] = useState<AttendanceType[]>([])
    const [coachings, setCoachings] = useState<CoachingType[]>([])
    const [recognitions, setRecognitions] = useState<RecognitionType[]>([])
    const [disciplines, setDisciplines] = useState<DisciplineType[]>([])

    const limit = 20

    const applyFilterView = (e: React.MouseEvent, type: string) => {
        setType(type);
    }

    useEffect(() => {
        let abort = new AbortController()

        getPagination(`api/attendances?limit=${limit}`, token)
            .then(({ data }) => setAttendances(data.items))
            .catch(err => console.log(err))

        getPagination(`api/coachings?limit=${limit}`, token)
            .then(({ data }) => setCoachings(data.items))
            .catch(err => console.log(err))

        getPagination(`api/recognitions?limit=${limit}`, token)
            .then(({ data }) => setRecognitions(data.items))
            .catch(err => console.log(err))

        getPagination(`api/disciplines?limit=${limit}`, token)
            .then(({ data }) => setDisciplines(data.items))
            .catch(err => console.log(err))

        return () => {
            abort.abort()
        }
    }, [token])

    return (
        <div className={`card ${className}`}>
            {/* begin::Header */}
            <div className='card-header align-items-center border-0 mt-4'>
                <h3 className='card-title align-items-start flex-column'>
                    <span className='fw-bolder mb-2 text-dark'>Recent Connections</span>
                    <span className='text-muted fw-bold fs-7'>{
                        type === 'Attendance' ? attendances.length :
                            type === 'Coaching' ? coachings.length :
                                type === 'Recognition' ? recognitions.length :
                                    type === 'Discipline' ? disciplines.length : 0
                    } {type}s</span>
                </h3>
                <div className='card-toolbar'>
                    {/* begin::Menu */}
                    <button
                        type='button'
                        className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                        data-kt-menu-trigger='click'
                        data-kt-menu-placement='bottom-end'
                        data-kt-menu-flip='top-end'
                    >
                        <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
                    </button>
                    <DropdownConnection applyFilter={(e, viewMode) => applyFilterView(e, viewMode)} />
                    {/* end::Menu */}
                </div>
            </div>
            {/* end::Header */}
            {/* begin::Body */}
            <div className='card-body pt-5'>
                {/* begin::Timeline */}
                {type === 'Attendance' && <div className={attendances.length > 0 ? `timeline-label` : `timeline-label2`}>
                    {attendances.length > 0 ?
                        attendances.map((attendance, i) => {
                            return <div className='timeline-item' key={i}>
                                <div className='timeline-label fw-bolder text-gray-800 fs-6'>{moment(attendance.date).format('MM/DD')}</div>
                                <div className='timeline-badge'>
                                    <i className='fa fa-genderless text-warning fs-1'></i>
                                </div>
                                <div className='fw-normal timeline-content text-muted ps-3'>
                                    <a href={`/team-member/${attendance.team?.id}/show/${attendance.employee?.id}`} className='text-dark fw-bolder text-hover-primary'>{attendance.employee?.firstName}</a><span className='text-warning ms-2'>Attendance</span><br></br>
                                    {/* <span>{rconnection.action ? rconnection.action.name : ''}</span><span className={`${rconnection.status === 'Pending' ? 'text-primary' : rconnection.status === 'Approved' || rconnection.status === 'ApprovedBySystem' ? 'text-success' : rconnection.status === 'Declined' ? 'text-danger' : ''} ms-2`}>{rconnection.status}</span> */}
                                </div>
                            </div>
                        })
                        :
                        <div className='fw-normal timeline-content-2 text-muted ps-3'>No data</div>
                    }
                </div>}
                {type === 'Coaching' && <div className={coachings.length > 0 ? `timeline-label` : `timeline-label2`}>
                    {coachings.length > 0 ?
                        coachings.map((coaching, i) => {
                            return <div className='timeline-item' key={i}>
                                <div className='timeline-label fw-bolder text-gray-800 fs-6'>{moment(coaching.date).format('MM/DD')}</div>
                                <div className='timeline-badge'>
                                    <i className='fa fa-genderless text-warning fs-1'></i>
                                </div>
                                <div className='fw-normal timeline-content text-muted ps-3'>
                                    <a href={`/team-member/${coaching.team?.id}/show/${coaching.employee?.id}`} className='text-dark fw-bolder text-hover-primary'>{coaching.employee?.firstName}</a><span className='text-primary ms-2'>Coaching</span><br></br>
                                    {/* <span>{rconnection.action ? rconnection.action.name : ''}</span><span className={`${rconnection.status === 'Pending' ? 'text-primary' : rconnection.status === 'Approved' || rconnection.status === 'ApprovedBySystem' ? 'text-success' : rconnection.status === 'Declined' ? 'text-danger' : ''} ms-2`}>{rconnection.status}</span> */}
                                </div>
                            </div>
                        })
                        :
                        <div className='fw-normal timeline-content-2 text-muted ps-3'>No data</div>
                    }
                </div>}
                {type === 'Recognition' && <div className={recognitions.length > 0 ? `timeline-label` : `timeline-label2`}>
                    {recognitions.length > 0 ?
                        recognitions.map((recognition, i) => {
                            return <div className='timeline-item' key={i}>
                                <div className='timeline-label fw-bolder text-gray-800 fs-6'>{moment(recognition.date).format('MM/DD')}</div>
                                <div className='timeline-badge'>
                                    <i className='fa fa-genderless text-warning fs-1'></i>
                                </div>
                                <div className='fw-normal timeline-content text-muted ps-3'>
                                    <a href={`/team-member/${recognition.team?.id}/show/${recognition.employee?.id}`} className='text-dark fw-bolder text-hover-primary'>{recognition.employee?.firstName}</a><span className='text-success ms-2'>Recognition</span><br></br>
                                    {/* <span>{rconnection.action ? rconnection.action.name : ''}</span><span className={`${rconnection.status === 'Pending' ? 'text-primary' : rconnection.status === 'Approved' || rconnection.status === 'ApprovedBySystem' ? 'text-success' : rconnection.status === 'Declined' ? 'text-danger' : ''} ms-2`}>{rconnection.status}</span> */}
                                </div>
                            </div>
                        })
                        :
                        <div className='fw-normal timeline-content-2 text-muted ps-3'>No data</div>
                    }
                </div>}
                {type === 'Discipline' && <div className={disciplines.length > 0 ? `timeline-label` : `timeline-label2`}>
                    {disciplines.length > 0 ?
                        disciplines.map((discipline, i) => {
                            return <div className='timeline-item' key={i}>
                                <div className='timeline-label fw-bolder text-gray-800 fs-6'>{moment(discipline.created_at).format('MM/DD')}</div>
                                <div className='timeline-badge'>
                                    <i className='fa fa-genderless text-warning fs-1'></i>
                                </div>
                                <div className='fw-normal timeline-content text-muted ps-3'>
                                    <a href={`/team-member/${discipline.team?.id}/show/${discipline.employee?.id}`} className='text-dark fw-bolder text-hover-primary'>{discipline.employee?.firstName}</a><span className='text-danger ms-2'>Discipline</span><br></br>
                                    <span style={{ color: discipline.step.stepColor }}>{discipline.step.name}</span><span className={`ms-2`}>{DisciplineStatusForHumansEnum[discipline.status]}</span>
                                </div>
                            </div>
                        })
                        :
                        <div className='fw-normal timeline-content-2 text-muted ps-3'>No data</div>
                    }
                </div>}
                {/* end::Timeline */}
            </div>
            {/* end: Card Body */}
        </div>
    )
}

export { RecentConnectionsWidget }
