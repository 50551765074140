import { ImportProcesses } from '../../../app/pages/superadmin/account/imports/constants/imports.models';

export interface EmployeeUploadApiRequestModel {
	tenant_id: string;
	processType: ImportProcesses;
	spreadsheetFiles: File[];
	createTeams: boolean;
	assignTeams: boolean;
	reassignTeams: boolean;
	optInNewEmployeesToTexting: boolean;
}

// from API: ImportEmployeesResults {
export interface EmployeeUploadApiResponse {
	success: boolean;
	error: boolean;
	results: {
		info: BatchProcessInfo<TeamReconcileInfo>[];
		issues: BatchProcessIssue<TeamReconcileIssue>[];
		employeesCreated: {
			count: number;
			info: BatchProcessInfo<TeamReconcileInfo>[];
			issues: BatchProcessIssue<TeamReconcileIssue.InvalidProperty>[];
		};
		employeesUpdated: {
			count: number;
			info: BatchProcessInfo<TeamReconcileInfo>[];
			issues: BatchProcessIssue<TeamReconcileIssue.InvalidProperty>[];
		};
		employeesTerminated: {
			count: number;
			info: BatchProcessInfo<TeamReconcileInfo>[];
			issues: BatchProcessIssue<TeamReconcileIssue.InvalidProperty>[];
		};
		employeesReassigned: {
			count: number;
			info: BatchProcessInfo<TeamReconcileInfo>[];
			issues: BatchProcessIssue<TeamReconcileIssue.InvalidProperty>[];
		};
		employeesAssigned: {
			count: number;
			info: BatchProcessInfo<TeamReconcileInfo>[];
			issues: BatchProcessIssue<TeamReconcileIssue.InvalidProperty>[];
		};
		teamsCreated: {
			count: number;
			info: BatchProcessInfo<TeamReconcileInfo.NewTeam>[];
			// not implemented/no failsafes
			issues: never[];
		};
	};
}

// from API
export interface BatchProcessInfo<T> {
	type: T;
	msg: string;
	misc?: {[key: string]: any};
}

export interface BatchProcessIssue<T> {
	type: T;
	msg: string;
	misc?: {[key: string]: any};
}

export enum TeamReconcileInfo {
	NewTeam = 'NEW_TEAM',
	EmployeeAssigned = 'EMPLOYEE_ASSIGNED',
	EmployeeReassigned = 'EMPLOYEE_REASSIGNED',
	EmployeeTerminated = 'EMPLOYEE_TERMINATED',
	EmployeeUpdated = 'EMPLOYEE_UPDATED',
	EmployeeCreated = 'EMPLOYEE_CREATED',
}

export enum TeamReconcileIssue {
	InvalidProperty = 'INVALID_PROPERTY',
	NoTeamColumn = 'NO_TEAM_COLUMN',
	TeamMissing = 'TEAM_MISSING',
	// TODO: see about removing, also update hris.service
	InvalidPhone = 'INVALID_PHONE',
}
