import moment from 'moment';
import { Recognition } from '../../../../features/recognition/interfaces/recognition.model';
import { US_FRIENDLY_DATE } from '../../../modules/date/DateFormat.const';
import { Discipline } from '../../../modules/discipline/models/Discipline.models';
import { AttendanceType } from '../../../types/AttendanceType';
import { CoachingType } from '../../../types/CoachingType';
import { ConnectionTypeEnum } from '../../../types/ConnectionType';
import { DisciplineType } from '../../../types/DisciplineType';
import { ConnectionHistoryAttendanceRow } from './ConnectionHistoryAttendanceRow';
import { ConnectionHistoryCoachingRow } from './ConnectionHistoryCoachingRow';
import { ConnectionHistoryCorrectiveActionRow } from './ConnectionHistoryCorrectiveActionRow';
import { ConnectionHistoryRecognitionRow } from './ConnectionHistoryRecognitionRow';

interface Props {
	combinedConnections: (AttendanceType | CoachingType | DisciplineType | Recognition)[];
	onAttendanceClick?: (attendance: AttendanceType, action: 'view' | 'edit' | 'delete') => void;
	onCoachingClick?: (coaching: CoachingType, action: 'view' | 'edit' | 'delete') => void;
	onCorrectiveActionClick?: (correctiveAction: Discipline, action: 'view' | 'edit' | 'delete') => void;
	startDate?: Date;
	endDate?: Date;
	showResetButton?: boolean;
	onResetDateSelection?: () => void;
}

export function ConnectionHistory({
																		combinedConnections,
																		onAttendanceClick,
																		onCoachingClick,
																		onCorrectiveActionClick,
																		startDate,
																		endDate,
																		showResetButton = false,
																		onResetDateSelection,
																	}: Props) {
	let previousDate: string | undefined = '';
	return (
		<div className="mb-6 mb-xl-9">
			<div className="d-flex mt-6 justify-content-start flex-row">
				<div className="">
					<h2 className="mb-1">Connection History
						{!!startDate && (
							<>
								{(!endDate || moment(startDate).isSame(endDate, 'day')) && (
									<span className="text-muted fw-light"> showing {moment(startDate).format(US_FRIENDLY_DATE)}</span>
								)}
								{(!!endDate && !moment(startDate).isSame(endDate, 'day')) && (
									<span className="text-muted fw-light"> showing {moment(startDate).format(US_FRIENDLY_DATE)} to {moment(endDate).format(US_FRIENDLY_DATE)}</span>
								)}
							</>
						)}
					</h2>
				</div>
				{showResetButton && (
					<button
						className="btn btn-outline-danger ms-auto"
						onClick={() => {
							if (onResetDateSelection) {
								onResetDateSelection();
							}
						}}
					>Reset Date</button>
				)}
			</div>
			<div className="pt-4">
				<table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 table-hover">
					<thead>
						<tr className="fw-bolder text-muted">
							<th>Date</th>
							<th>Type</th>
							<th>Category</th>
							<th>Notes</th>
							<th>Creator</th>
							<th>Actions</th>
						</tr>
					</thead>
					<tbody>
						{combinedConnections.map((item, i) => {
							const skipDate = (item.date === previousDate);
							previousDate = item.date;
							switch (item.connectionType) {
								case ConnectionTypeEnum.Attendance:
									return (
										<ConnectionHistoryAttendanceRow
											attendance={(item as AttendanceType)}
											rxKey={i}
											key={i}
											onClick={(att) => {
												if (onAttendanceClick) {
													onAttendanceClick(att, 'view');
												}
											}}
											onActionClick={(att, action) => {
												if (onAttendanceClick) {
													onAttendanceClick(att, action);
												}
											}}
											showDate={!skipDate}
										/>
									);
								case ConnectionTypeEnum.Coaching:
									return <ConnectionHistoryCoachingRow
										coaching={(item as CoachingType)}
										rxKey={i}
										key={i}
										onClick={(coa) => {
											if (onCoachingClick) {
												onCoachingClick(coa, 'view');
											}
										}}
										onActionClick={(coa, action) => {
											if (onCoachingClick) {
												onCoachingClick(coa, action);
											}
										}}
										showDate={!skipDate} />;
								case ConnectionTypeEnum.Recognition:
									return <ConnectionHistoryRecognitionRow
										recognition={(item as unknown as Recognition)}
										rxKey={i}
										key={i}
										showDate={!skipDate} />;
								case ConnectionTypeEnum.Discipline:
									return (
										<ConnectionHistoryCorrectiveActionRow
											correctiveAction={(item as unknown as Discipline)}
											rxKey={i}
											key={i}
											onClick={(corr) => {
												if (onCorrectiveActionClick) {
													onCorrectiveActionClick(corr, 'view');
												}
											}}
											onActionClick={(corr, action) => {
												if (onCorrectiveActionClick) {
													onCorrectiveActionClick(corr, action);
												}
											}}
											showDate={!skipDate} />
									);
								default:
									return null;
							}
						})
						}
					</tbody>
				</table>
			</div>
		</div>
	);
}
