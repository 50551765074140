import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { NavLink } from 'react-router-dom';
import { PageTitle } from '../../../_library/layout/core';
import { ConnectionsGrid } from '../../../features/connection/pages/ConnectionsGrid';
import AttendancesList from './attendance/AttendancesList';
import CoachingsList from './coaching/CoachingsList';
import DisciplinesList from './discipline/DisciplinesList';
import { RecognitionsList } from './recognition/RecognitionsList';

type RouteParams = {
	tab?: string;
	type?: string;
}


export function ConnectionsList() {
	const {tab} = useParams<RouteParams>();
	const [activeTab, setActiveTab] = useState<string>(tab || 'attendance');

	useEffect(() => {
		if (tab) {
			setActiveTab(tab);
		}
	}, [tab]);

	return (
		<>
			<PageTitle breadcrumbs={[]}>Connections</PageTitle>
			<div>
				<ul className="nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-6">
					{[
						'all',
						'recognition',
						'coaching',
						'attendance',
						'corrective-action',
					].map(tabName => (
						<li
							className="nav-item"
							key={tabName}>
							<NavLink
								to={`../${tabName}`}
								relative="path"
								className={({
															isActive,
															isPending,
														}) =>
									clsx('nav-link',
										{
											pending: isPending,
											active: isActive || tabName === activeTab,
										},
									)}>
								{tabName === 'corrective-action' && 'Corrective Action'}
								{tabName !== 'corrective-action' && tabName.charAt(0).toUpperCase() + tabName.slice(1)}
							</NavLink>
						</li>
					))}
				</ul>
				<div
					className="tab-content"
					id="connectionsTabContent">
					{activeTab === 'all' && <ConnectionsGrid />}
					{activeTab === 'attendance' && <AttendancesList />}
					{activeTab === 'recognition' && <RecognitionsList />}
					{activeTab === 'coaching' && <CoachingsList />}
					{activeTab === 'corrective-action' && <DisciplinesList />}
				</div>
			</div>
		</>
	);
};
