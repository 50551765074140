import { configureStore } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
import { reduxBatch } from '@manaflair/redux-batch'
import { persistStore } from 'redux-persist'
import { attendanceSlice } from '../../features/attendance/attendance.api';
import { disciplineStepSlice } from '../../features/discipline/discipline-step.api';
import { coachingCategorySlice } from '../../features/coaching/coaching-categories.api';
import { disciplineSlice } from '../../features/discipline/discipline.api';
import { recognitionSlice } from '../../features/recognition/recognition.api';
import { teamSlice } from '../../features/team/team.api';
import { attendanceRuleSlice } from '../../features/attendance/attendance-rule.api';
import { attendanceReasonsSlice } from '../../features/attendance/attendance-reasons.api';
import { activitySlice } from '../../features/activity/activity.api';
import { recognitionCategoriesSlice } from '../../features/recognition/recognition-categories.api';
import { rootReducer, rootSaga } from './RootReducer'
import {companySlice} from "../../features/company/company.api";
import {coachingSlice} from '../../features/coaching/coaching.api'

const sagaMiddleware = createSagaMiddleware()

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
      thunk: true,
    }),
    activitySlice.middleware,
    attendanceSlice.middleware,
    attendanceReasonsSlice.middleware,
    attendanceRuleSlice.middleware,
    coachingCategorySlice.middleware,
    coachingSlice.middleware,
    companySlice.middleware,
    disciplineSlice.middleware,
    disciplineStepSlice.middleware,
    recognitionCategoriesSlice.middleware,
    recognitionSlice.middleware,
    sagaMiddleware,
    teamSlice.middleware,
  ],
  devTools: process.env.NODE_ENV !== 'production',
  enhancers: [reduxBatch],
})

export type AppDispatch = typeof store.dispatch

/**
 * @see https://github.com/rt2zz/redux-persist#persiststorestore-config-callback
 * @see https://github.com/rt2zz/redux-persist#persistor-object
 */
export const persistor = persistStore(store)

sagaMiddleware.run(rootSaga)

export default store
