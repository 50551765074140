import { skipToken } from '@reduxjs/toolkit/query';
import { compact, concat } from 'lodash';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { sortDateDescending } from '../../../_library/helpers/sortDate';
import { PageLink, PageTitle } from '../../../_library/layout/core';
import { ModalDisplayMode } from '../../../common/interfaces/modal.model';
import {
	Recognition,
	RecognitionsGetAllApiRequestModel,
} from '../../../features/recognition/interfaces/recognition.model';
import { recognitionSlice } from '../../../features/recognition/recognition.api';
import { RecognitionModalCreateState, recognitionModalSlice } from '../../../features/recognition/recognition-modal.slice';
import { teamSlice } from '../../../features/team/team.api';
import { RootState } from '../../../setup';
import { Icon } from '../../components/icons/Icon';
import { Download } from '../../components/icons/IconList';
import {
	getEmployee,
	getEmployeeAttendances,
	getEmployeeCoaching,
	getEmployeeDisciplines,
} from '../../CRUD/TeamCRUD';
import { AttendanceGetParamsModel } from '../../modules/attendance/models/AttendanceGetParamsModel';
import { SHORT_DATE } from '../../modules/date/DateFormat.const';
import { CorrectiveActionModal } from '../../modules/discipline/components/CorrectiveActionModal';
import { Employee } from '../../modules/employee/models/Employee.model';
import { AttendanceType } from '../../types/AttendanceType';
import { CoachingType } from '../../types/CoachingType';
import { ConnectionType, ConnectionTypeEnum, ConnectionUnion } from '../../types/ConnectionType';
import { DisciplineType } from '../../types/DisciplineType';
import { EmployeeType, TeamType } from '../../types/EmployeeType';
import { PerformanceReportConfigType } from '../../types/PerformanceReportConfigType';
import { SortDateEnum } from '../../types/SortDateEnum';
import { AttendanceModal } from '../connections/attendance/AttendanceModal';
import { AddCoachingModal } from '../connections/coaching/AddCoachingModal';
import { EmployeePerformanceCard } from '../dashboard/_modals/employee/EmployeePerformanceCard';
import { EmployeePerformanceCardConfig } from '../dashboard/_modals/employee/EmployeePerformanceCardConfig';
import { ChangeTeamModal } from './components/ChangeTeamModal';
import { EmployeeCardInformation } from './components/EmployeeCardInformation';
import { TeamCard } from './components/TeamCard';
import './TeamMemberView.scss';

type RouteParams = {
	teamId: string;
	id: string;
}

const parseConnectionsForCalendars = (item: (ConnectionUnion|Recognition)) => {
	let type: ConnectionType = item.connectionType!;
	if (type === 'ATTENDANCE' && (item as AttendanceType).reason.isExcused) {
		type = ConnectionTypeEnum.Excused;
	}
	return {
		type,
		// needed for monthly calendar
		yearMonth: item.date?.slice(0, 7),
		date: item.date,
		// both needed for yearly calendar
		startDate: moment(item.date).toDate(),
		endDate: moment(item.date).toDate(),
	};
};

const TeamMemberView: React.FC = () => {
	const {
		teamId,
		id,
	} = useParams<RouteParams>();
	const token: string = useSelector<RootState>(({ auth }) => auth.accessToken, shallowEqual) as string;
	const [employee, setEmployee] = useState<EmployeeType>();
	const [attendances, setAttendances] = useState<AttendanceType[]>([]);
	const [coachings, setCoachings] = useState<CoachingType[]>([]);
	const [disciplines, setDisciplines] = useState<DisciplineType[]>([]);
	const [coachingSources, setCoachingSources] = useState<Array<any>>([]);
	const [recognitionSources, setRecognitionSources] = useState<Array<any>>([]);
	const [attendanceSources, setAttendanceSources] = useState<Array<any>>([]);
	const [disciplineSources, setDisciplineSources] = useState<Array<any>>([]);
	const showActionModalRef = React.useRef<HTMLButtonElement>(null);
	const [combinedConnections, setCombinedConnections] = useState<(AttendanceType|CoachingType|DisciplineType|Recognition)[]>([]);
	const [showNewDiscipline, setShowNewDiscipline] = useState<boolean>(false);

	// for display on the employee profile
	const [connectionStartDate, setConnectionStartDate] = useState<Date>(moment().subtract(1, 'year').toDate());
	// grabbing 1 month out for Corrective Actions scheduled in the future
	const [connectionEndDate, setConnectionEndDate] = useState<Date>(moment().add(1, 'month').toDate());

	// for display on the instant performance report
	const [reportStartDate, setReportStartDate] = useState<Date>(moment().subtract(1, 'year').toDate());
	const [reportEndDate, setReportEndDate] = useState<Date>(moment().add(1, 'day').toDate());
	const [reportAttendances, setReportAttendances] = useState<AttendanceType[]>([]);
	const [reportCoachings, setReportCoachings] = useState<CoachingType[]>([]);
	const [reportRecognitions, setReportRecognitions] = useState<Recognition[]>([]);
	const [reportDisciplines, setReportDisciplines] = useState<DisciplineType[]>([]);
	const [performanceReportConfig, setPerformanceReportConfig] = useState<PerformanceReportConfigType>({
		attendance: true,
		excused: false,
		coaching: true,
		combineConnections: false,
		discipline: true,
		recognition: true,
		startDate: reportStartDate,
		endDate: reportEndDate,
	});
	const { data: teams, error, isLoading } = teamSlice.useGetAllTeamsQuery();
	const {  data: teamInfo, isSuccess: teamSuccess } = teamSlice.useGetOneTeamQuery(teamId ? {team_id: +teamId} : skipToken);
	const connectionsFilter: RecognitionsGetAllApiRequestModel = {
		// `id` has to be here as it's non-optional on the route
		employeeId: +id!,
		fromDate: moment(connectionStartDate).format(SHORT_DATE),
		toDate: moment(connectionEndDate).format(SHORT_DATE),
		limit: 100,
	}
	const { data: recognitions } = recognitionSlice.useGetRecognitionsQuery(connectionsFilter, {refetchOnMountOrArgChange: true});
	const updateSources = useMemo(() => (new Date().toTimeString()), [recognitions?.items.length, id, teamId]);
	// let performanceReportConnectionsFilter: RecognitionsGetAllApiRequestModel = {
	// 	// `id` has to be here as it's non-optional on the route
	// 	employeeId: +id!,
	// }
	// const { data: reportRecognitions,
	// 	isLoading: isReportRecognitionsLoading,
	// } = recognitionSlice.useGetRecognitionsQuery(performanceReportConnectionsFilter.toDate ? performanceReportConnectionsFilter : skipToken, {refetchOnMountOrArgChange: true});
	const [getReportRecognitionsQuery] = recognitionSlice.useLazyGetRecognitionsQuery();

	const dispatch = useDispatch();
	const handleNewRecognitionClick = () => {
		const payload: RecognitionModalCreateState = {
			mode: ModalDisplayMode.Create,
			employee: (employee as unknown as Employee),
		}
		dispatch(recognitionModalSlice.actions.show(payload));
	};

	useEffect(() => {
		if (recognitions?.items.length) {
			setRecognitionSources(recognitions.items.map(parseConnectionsForCalendars));
		}
	}, [updateSources]);

	const refreshEmployeeData = () => {
		if (!id) {
			return console.error('No employee id set');
		}
		getEmployee(+id, token)
			.then(({ data }) => {
				setEmployee(data);
			})
			.catch(err => {
				console.log(err.response);
			});
	};

	useEffect(() => {
		refreshEmployeeData();

			loadConnectionsForHistory();
	}, [id, token, teamId]);

	const loadConnectionsForHistory = async () => {
		const att = await getAttendances(connectionStartDate, connectionEndDate);
		setAttendanceSources(att.map(parseConnectionsForCalendars));
		setAttendances(att);

		const coach = await getCoachings(connectionStartDate, connectionEndDate);
		setCoachingSources(coach.map(parseConnectionsForCalendars));
		setCoachings(coach);

		const disc = await getDisciplines(moment(connectionStartDate).format(SHORT_DATE), moment(connectionEndDate).format(SHORT_DATE));
		setDisciplineSources(disc.map(parseConnectionsForCalendars));
		setDisciplines(disc);
	};

	const getAttendances = async (fromDate: Date, toDate: Date): Promise<AttendanceType[]> => {
		const opts: AttendanceGetParamsModel = {
			fromDate: moment(fromDate).format(SHORT_DATE),
			toDate: moment(toDate).format(SHORT_DATE),
			sort: SortDateEnum.Desc,
			includeFuture: 'true',
			limit: 100,
		};
		if (!id) {
			throw new Error('No employee id set');
		}
		try {
			const { data } = await getEmployeeAttendances(+id, token, opts);
			return data.items;
		} catch (err: any) {
			console.log(err.response);
			return [];
		}

	};

	const getCoachings = async (fromDate: Date, toDate: Date): Promise<CoachingType[]> => {
		const opts = {
			fromDate: moment(fromDate).format(SHORT_DATE),
			toDate: moment(toDate).format(SHORT_DATE),
			sort: SortDateEnum.Desc,
			limit: 100,
		};
		if (!id) {
			throw new Error('No employee id set');
		}
		try {
			const { data } = await getEmployeeCoaching(parseInt(id), token, opts);
			return data.items;
		} catch (err: any) {
			console.log(err.response);
			return [];
		}

	};

	const getDisciplines = async (fromDate: string, toDate: string): Promise<DisciplineType[]> => {
		const opts = {
			fromDate: moment(fromDate).format(SHORT_DATE),
			toDate: moment(toDate).format(SHORT_DATE),
			sort: SortDateEnum.Desc,
			limit: 100,
		};
		if (!id) {
			throw new Error('No employee id set');
		}
		try {
			const { data } = await getEmployeeDisciplines(parseInt(id), token, opts);
			return data.items;
		} catch (err: any) {
			console.log(err.response);
			return [];
		}

	};

	const refreshData = async () => {
		refreshEmployeeData();
		await loadConnectionsForHistory();
	};

	useEffect(() => {
		const newConnections: (AttendanceType|CoachingType|DisciplineType|Recognition)[] = compact(
			concat<(AttendanceType|CoachingType|DisciplineType|Recognition|undefined)>(
				attendances,
				coachings,
				disciplines,
				recognitions?.items,
			)
		);
		newConnections.sort(sortDateDescending);
		setCombinedConnections(newConnections);
	}, [attendances, coachings, disciplines, recognitions]);

	const getTeamLink = (): PageLink[] => {
		if (employee && employee.teams?.[0]) {
			return [
				{
					isActive: true,
					path: `/teams/show/${employee.teams[0].id}`,
					title: employee.teams[0].name,

				},
			];
		}
		return [];
	};

	const handleGenerateReport = async (config: PerformanceReportConfigType) => {
		// performanceReportConnectionsFilter = {
		// 	...performanceReportConnectionsFilter,
		// 	fromDate: moment(config.startDate).format(SHORT_DATE),
		// 	toDate: moment(config.endDate).format(SHORT_DATE),
		// };

		Promise.all([
						 getAttendances(moment(config.startDate).toDate(), moment(config.endDate).toDate()),
						 getCoachings(moment(config.startDate).toDate(), moment(config.endDate).toDate()),
						 getReportRecognitionsQuery({
							 employeeId: +id!,
							 fromDate: moment(config.startDate).format(SHORT_DATE),
							 toDate: moment(config.endDate).format(SHORT_DATE),
							 sort: SortDateEnum.Desc,
							 limit: 100}).unwrap(),
						 getDisciplines(moment(config.startDate).format(SHORT_DATE), moment(config.endDate).format(SHORT_DATE)),
					 ])
					 .then(val => {
						 const [att, coach, recog, disc] = val;

						 setReportAttendances(att);
						 setReportCoachings(coach);
						 setReportRecognitions(recog?.items);
						 setReportDisciplines(disc);

						 if(showActionModalRef.current) {
							 showActionModalRef.current.click();
						 }
					 })
					 .catch(err => {
						 console.log('Error loading connections for Instant Performance Report', err);
					 });
	}

	return (
		<>
			<PageTitle breadcrumbs={getTeamLink()}>Team Member</PageTitle>
			<div className="d-flex flex-column flex-lg-row">
				{employee?.id && <TeamCard employee={employee}
																	 refreshEmployeeData={refreshEmployeeData} />}
				<div className="flex-lg-row-fluid ms-lg-15">
					<div className="d-flex mb-8 flex-row flex-wrap">
						<button
							type="button"
							className="btn btn-outline btn-outline-primary btn-active-light-primary px-5 m-1"
							onClick={handleNewRecognitionClick}
						>
							New Recognition
						</button>
						<button
							type="button"
							className="btn btn-outline btn-outline-primary btn-active-light-primary px-5 m-1"
							data-bs-toggle="modal"
							data-bs-target="#add_coaching_modal"
						>
							New Coaching
						</button>
						<button
							type="button"
							className="btn btn-outline btn-outline-primary btn-active-light-primary px-5 m-1"
							data-bs-toggle="modal"
							data-bs-target='#new-attendance-from-team-member-view'
						>
							New Attendance
						</button>
						<button
							type="button"
							className="btn btn-outline btn-outline-primary btn-active-light-primary px-5 m-1"
							data-bs-toggle="modal"
							data-bs-target="#corrective-action-from-team-member-view"
							onClick={() => setShowNewDiscipline(true)}
						>
							New Corrective Action
						</button>
						<button
							type="button"
							className="btn btn-light-primary ms-auto"
							data-bs-toggle="modal"
							data-bs-target="#employee_performance_card_config"
						>
							<Icon
								type="svg"
								icon={Download}
								classes="me-1"
								size="sm"></Icon>
							<span>Instant Performance Report</span>
						</button>
					</div>
					{/*end:::Tabs*/}
					{/*begin:::Tab content*/}

					<EmployeeCardInformation
						attendanceSources={attendanceSources}
						coachingSources={coachingSources}
						recognitionSources={recognitionSources}
						disciplineSources={disciplineSources}
						combinedConnections={combinedConnections}
						employee={employee!}
						refresh={refreshData}
					/>
					{/*end:::Tab content*/}
				</div>
			</div>
			{employee?.id &&
				<>
					<EmployeePerformanceCard
						employee={employee}
						attendances={reportAttendances}
						coachings={reportCoachings}
						recognitions={reportRecognitions || []}
						disciplines={reportDisciplines}
						config={performanceReportConfig}
					/>
					<EmployeePerformanceCardConfig
						config={performanceReportConfig}
						setConfig={setPerformanceReportConfig}
						onGenerateReport={handleGenerateReport}
					/>

					<button
						className="d-none"
						type="button"
						data-bs-toggle="modal"
						data-bs-target="#employee_performance_card"
						ref={showActionModalRef}
					></button>
					<AddCoachingModal
						employee={employee}
						teamInfo={(teamInfo as unknown as TeamType)}
						refresh={refreshData}
					/>
					{employee && <AttendanceModal
						employee={employee}
						modalId='new-attendance-from-team-member-view'
						mode="new"
						refresh={refreshData}
					/>}
					<ChangeTeamModal
						employee={employee}
						teams={(teams as unknown as TeamType[])}
						refresh={refreshEmployeeData}
					/>
					<CorrectiveActionModal
						refresh={() => {
							refreshData();
							setShowNewDiscipline(false);
						}}
						show={showNewDiscipline}
						modalId="corrective-action-from-team-member-view"
						employee={(employee as unknown as Employee)} />
				</>
			}
		</>
	);
};

export { TeamMemberView };
