import { useSelector } from 'react-redux';
import { CoachingModalState, CoachingModalStateUnion } from '../../features/coaching/coaching-modal.slice';
import { CoachingLayout } from '../../features/coaching/components/layout/CoachingLayout';
import { DisciplineLayout } from '../../features/discipline/components/layout/DisciplineLayout';
import { DisciplineModalState, DisciplineModalStateUnion } from '../../features/discipline/discipline-modal.slice';
import { RecognitionLayout } from '../../features/recognition/components/layout/RecognitionLayout';
import { RecognitionModalState, RecognitionModalStateUnion } from '../../features/recognition/recognition-modal.slice';
import { RootState } from '../../setup';
import { AttendanceLayout } from '../../features/attendance/components/layout/AttendanceLayout';
import { AttendanceModalState, AttendanceModalStateUnion } from '../../features/attendance/attendance-modal.slice';

export function ModalManager() {
	// TODO: figure out better way to set this for state vs action payloads
	const recognitionModalState = useSelector<RootState>(state => state.recognitionModal) as RecognitionModalStateUnion & Pick<RecognitionModalState, 'showModal' | 'recordId'>;
	const coachingModalState = useSelector<RootState>(state => state.coachingModal) as CoachingModalStateUnion & Pick<CoachingModalState, 'showModal'>;
	const attendanceModalState = useSelector<RootState>(state => state.attendanceModal) as AttendanceModalStateUnion & Pick<AttendanceModalState, 'showModal'>;
	const disciplineModalState = useSelector<RootState>(state => state.disciplineModal) as DisciplineModalStateUnion & Pick<DisciplineModalState, 'showModal'>;


	return (
		<>
			<RecognitionLayout
				state={recognitionModalState}
			/>
			<CoachingLayout
				state={coachingModalState}
			/>
			<AttendanceLayout
				state={attendanceModalState}
			/>
			<DisciplineLayout
				state={disciplineModalState}
			/>
		</>
	);
}
