import clsx from 'clsx';
import { RequiredDeep, SetOptional } from 'type-fest';
import { Employee } from '../modules/employee/models/Employee.model';
import { Team } from '../modules/team/models/Team.model';
import { EmployeeType, TeamType } from '../types/EmployeeType';
import { Avatar, AvatarSize } from './Avatar';

interface Props {
	employee?: RequiredDeep<Pick<(Employee | EmployeeType), 'firstName' | 'lastName' | 'avatarUrl'>> & SetOptional<Pick<(Employee | EmployeeType), 'teams'>, 'teams'>;
	team?: Required<Pick<(Team | TeamType), 'name'>>;
	size?: AvatarSize
}

export function EmployeeBadge({ employee, team, size = AvatarSize.lg }: Props) {
	const teamName = team?.name || employee?.teams?.[0]?.name || 'unassigned';
	return (
		<div className={clsx("p-1 ps-2 align-items-center d-flex bg-secondary rounded-1", {
			invisible: !employee,
		})}>
			<Avatar
				size={size}
				alt={`Employee ${employee?.firstName} ${employee?.lastName} avatar`}
				url={employee?.avatarUrl}/>
			<div className="px-3 ms-2">
				<div className="text-dark">
					{employee?.firstName} {employee?.lastName}
				</div>
				<div className="text-muted">
					{teamName}
				</div>
			</div>
		</div>
	)
}
