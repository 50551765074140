export interface PerformanceCategory {
	id: number;
	tenant_id: string;
	name: string;
	isArchived: boolean;
	templates: TemplateModel[];
	// group_id: number;
	type: CategoryTypeEnum;
	metadata?: unknown;
	updated_at: string;
	created_at: string;
	subtype: CategorySubtypeEnum;
}

export interface PerformanceCategoryApiResponse {
	items: PerformanceCategory[];
}

export interface PerformanceCategoryGetParams {
	isArchived?: boolean;
}

export enum CategoryTypeEnum {
	Discipline = 'DISCIPLINE',
	Coaching = 'COACHING',
	Attendance = 'ATTENDANCE',
	Recognition = 'RECOGNITION',
}

export enum CategorySubtypeEnum {
	Performance = 'PERFORMANCE',
	Attendance = 'ATTENDANCE',
}


// TODO: split out when implement category controller/endpoint
export interface TemplateModel {
	id: number;
	tenant_id: string;
	// TODO: CompanyGroup update to be a string
	group_id: null;
	type: TemplateTypeEnum;
	category_id: number;
	// category
	name: string;
	description: string | null;
	template: TemplateData;
	updated_at: string;
	created_at: string;
	deleted_at: string | null;
}

export enum TemplateTypeEnum {
	Discipline = 'DISCIPLINE',
}

export interface TemplateData {
	fields: {
		incidentDetails?: string;
		consequences?: string;
		expectations?: string;
	};
}
