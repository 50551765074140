/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'

type ChildProps = {
  applyFilter: (e: React.MouseEvent, viewMode: string) => void;
}

export const DropdownConnection: React.FC<ChildProps> = (props) => {
  const [connectionType, setConnectionType] = useState<string>('Attendance')

  return (
    <div className='menu menu-sub menu-sub-dropdown w-250px w-md-300px' data-kt-menu='true'>
      <div className='px-7 py-5'>
        <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
      </div>

      <div className='separator border-gray-200'></div>

      <div className='px-7 py-5'>
        <div className='mb-10'>
          <label className='form-label fw-bold'>Connection Type:</label>

          <div>
            <select
              className='form-select form-select-solid'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              defaultValue={'Attendance'}
              onChange={(e) => setConnectionType(e.target.value)}
            >
              <option value='Attendance'>Attendance</option>
              <option value='Performance'>Performance</option>
              <option value='Coaching'>Coaching</option>
              <option value='Recognition'>Recognition</option>
              <option value='Discipline'>Discipline</option>
            </select>
          </div>
        </div>
        
        <div className='d-flex justify-content-end'>
          {/* <button
            type='reset'
            className='btn btn-sm btn-white btn-active-light-primary me-2'
            data-kt-menu-dismiss='true'
          >
            Reset
          </button> */}

          <button type='submit' className='btn btn-sm btn-primary' data-kt-menu-dismiss='true' onClick={(e) => props.applyFilter(e, connectionType)}>
            Apply
          </button>
        </div>
      </div>
    </div>
  )
}