import { AttendanceType } from './AttendanceType';
import { CoachingType } from './CoachingType';
import { DisciplineType } from './DisciplineType';
import { RecognitionType } from './RecognitionType';


export enum ConnectionTypeEnum {
	Attendance = 'ATTENDANCE',
	Coaching = 'COACHING',
	Recognition = 'RECOGNITION',
	Discipline = 'DISCIPLINE',
	Excused = 'EXCUSED',
}

export type ConnectionType =
	ConnectionTypeEnum.Attendance |
	ConnectionTypeEnum.Coaching |
	ConnectionTypeEnum.Recognition |
	ConnectionTypeEnum.Discipline |
	ConnectionTypeEnum.Excused;

export type ConnectionUnion = (AttendanceType | CoachingType | DisciplineType | RecognitionType);
