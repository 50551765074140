import { Document, Font, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { Recognition } from '../../interfaces/recognition.model';

interface Props {
	recognition?: Recognition;
}

Font.register({
	family: 'PinyonScript-Regular',
	src: '/static/media/PinyonScript-Regular.8d81e07e5bc82ec02bf7.ttf',
});

const goldColor = '#a67c00'

const styles = StyleSheet.create({
	page: {
		flexDirection: 'column',
		backgroundColor: '#FFFFFF',
		alignItems: 'center',
		padding: 40,
		fontFamily: 'Times-Italic',
	},
	certificateContainer: {
		borderWidth: 3,
		borderColor: goldColor,
		padding: 24,
		width: '100%',
		height: 'auto',
		fontFamily: 'Times-Italic',
	},
	title: {
		fontSize: 40,
		color: goldColor,
		textAlign: 'center',
		width: '100%',
		marginBottom: 12,
		justifyContent: 'center',
		flexDirection: 'row',
		fontFamily: 'PinyonScript-Regular',
	},
	awardText: {
		fontSize: 20,
		textAlign: 'center',
		marginBottom: 16,
		fontFamily: 'Times-Roman',
		fontWeight: 'extralight',
	},
	recipientName: {
		fontSize: 36,
		textAlign: 'center',
		justifyContent: 'center',
		flexDirection: 'row',
		color: goldColor,
		margin: 2,
		fontFamily: 'Times-Bold',
	},
	recognitionText: {
		fontSize: 20,
		textAlign: 'center',
		margin: 16,
		fontFamily: 'Times-Italic',
	},
	signature: {
		fontSize: 16,
		textAlign: 'center',
		marginTop: 8,
		color: goldColor,
		fontFamily: 'Times-Italic',
	},
	signatureLine: {
		borderBottomWidth: 1,
		borderTopColor: goldColor,
		width: '50%',
		marginTop: 4,
		alignSelf: 'center',
	},
	footer: {
		fontSize: 12,
		textAlign: 'left',
		marginTop: 24,
		fontFamily: 'Helvetica',
		textTransform: 'uppercase',
		textDecoration: 'underline',
	},
	logo: {
		width: 'auto',
		height: 32,
		position: 'absolute',
		bottom: 12,
		left: 12,
	},

});


export function RecognitionCertificateContent({ recognition }: Props) {

	const removeSignatureFromMessage = (message: string, creator: Recognition['creator']): string => {
		const initials = `${creator.firstName.charAt(0)}${creator.lastName.charAt((0))}`;
		const fullName = `${creator.firstName} ${creator.lastName}`;
		const firstName = `${creator.firstName}`;
		const signatureRegexpPattern = `\\s*\\-(\\b${fullName}\\b|${initials}|${firstName})\\s*$`;
		const regexp = new RegExp(signatureRegexpPattern, 'gi');
		return message.replace(regexp, '');
	}

	if (!recognition) {
		return null;
	}

	return (
		<Document>
			<Page size="A4"
						style={styles.page}>
				<View style={styles.certificateContainer}>
					<Image style={styles.logo}
								 src="public/media/logos/simple_logo.png" />
					<Text style={[styles.title]}>Certificate of Recognition</Text>
					<Text style={styles.awardText}>This award is given to</Text>
					<Text style={[
						styles.recipientName,
					]}>{recognition?.employee.firstName} {recognition?.employee.lastName}</Text>
					<Text style={styles.awardText}>For</Text>
					<Text style={styles.recognitionText}>{removeSignatureFromMessage(recognition.translatedMessage || recognition.message, recognition.creator)}</Text>
					<View style={styles.signatureLine} />
					<Text style={[
						styles.signature,
					]}>{recognition?.creator?.firstName} {recognition?.creator?.lastName} on {recognition?.date}</Text>
					<Image
						style={styles.logo}
						src='/static/media/secchi_light_logo.5063e4dbcd79cab411b4.png' />
				</View>
			</Page>
		</Document>
	);
}
