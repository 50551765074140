import { skipToken } from '@reduxjs/toolkit/query';
import {useEffect, useRef, useState} from 'react';
import { useDispatch } from 'react-redux';
import { RecognitionModal } from '../../../../app/pages/connections/recognition/RecognitionModal';
import { RecognitionViewModal } from '../../../../app/pages/connections/recognition/RecognitionViewModal';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { SkeDialogDelete } from '../../../../common/components/dialog-delete';
import { ModalDisplayMode } from '../../../../common/interfaces/modal.model';
import { recognitionSlice } from '../../recognition.api';
import { recognitionModalSlice, RecognitionModalState, RecognitionModalStateUnion } from '../../recognition-modal.slice';
import { RecognitionCertificateContent } from '../modals/RecognitionCertificateContent';

interface Props {
	state: RecognitionModalStateUnion & Pick<RecognitionModalState, 'showModal' | 'recordId'>;
}

export function RecognitionLayout({ state }: Props) {
	const dispatch = useDispatch();
	const [deleteRecognition] = recognitionSlice.useDeleteRecognitionMutation();
	const downloadButtonRef = useRef<HTMLButtonElement>(null);
	const recognitionViewModalRef = useRef<HTMLButtonElement>(null);
	const recognitionEditModalRef = useRef<HTMLButtonElement>(null);
	const previousState = useRef<RecognitionModalStateUnion>();
	const [getOneRecognitionFilter, setGetOneRecognitionFilter] = useState<{id: number}>();
	const {
		data: recognition,
		refetch,
		isUninitialized,
		fulfilledTimeStamp,
	} = recognitionSlice.useGetOneRecognitionQuery( getOneRecognitionFilter || skipToken)

	useEffect(() => {
		if (!state.record && state.recordId) {
			const force = !state.record && !!previousState.current?.record;
			handleLoadRecord(state.recordId, force);
			return;
		}
		if (state.record && state.record?.id === state.recordId && state.showModal) {
			switch (state.mode) {
				case ModalDisplayMode.Read:
					recognitionViewModalRef.current?.click();
					break;
				case ModalDisplayMode.Update:
					recognitionEditModalRef.current?.click();
					break;
			}
			previousState.current = state;
			return;
		}
	}, [state]);

	useEffect(() => {
		if (!!recognition && recognition.id === state.recordId) {
			dispatch(recognitionModalSlice.actions.show({
				...state,
				record: recognition,
			}));
		}
	}, [recognition, fulfilledTimeStamp]);

	const handleLoadRecord = (id: number, force: boolean) => {
		setGetOneRecognitionFilter({id});
		if (force) {
			if (isUninitialized) {
				return;
			}
			refetch();
		}
	};

	const handleCancelDelete = () => {
		dispatch(recognitionModalSlice.actions.hide());
	};

	const handleConfirmDelete = (errorToast: Function, successToast: Function) => {
		if (state.mode !== ModalDisplayMode.Delete || !state.recordId) {
			throw new Error('Unable to delete record as no ID was passed in');
		}
		deleteRecognition({ id: state.recordId})
		.unwrap()
		.then(() => {
			successToast();
			dispatch(recognitionModalSlice.actions.hide());
		})
		.catch(() => {
			errorToast();
			dispatch(recognitionModalSlice.actions.hide());
		});
	};

	return (
		<>
			<button
				className="d-none"
				ref={recognitionViewModalRef}
				// data-bs-toggle="modal" -- React Modal does not like this tag
				data-bs-target="#connections-recognition-view">
			</button>
			<button
				className="d-none"
				ref={recognitionEditModalRef}
				// data-bs-toggle="modal" -- React Modal does not like this tag
				data-bs-target="#connections-recognition-edit">
			</button>
			{ state.record && state.mode === ModalDisplayMode.Read &&
				<RecognitionViewModal
					recognition={state.record}
					modalId="connections-recognition-view"
					onClose={() => dispatch(recognitionModalSlice.actions.hide())}
				/>
			}
			{ state.record && state.mode === ModalDisplayMode.Update &&
				<RecognitionModal
					mode='edit'
					recognition={state.record}
					modalId="connections-recognition-edit"
					refresh={() => dispatch(recognitionModalSlice.actions.hide())}
					onClose={() => dispatch(recognitionModalSlice.actions.hide())}
					employee={state.record.employee} />
			}
			{state.showModal && (
				<>
					{state.mode === ModalDisplayMode.Create && (
						<RecognitionModal
							mode='new'
							employee={state.employee} />
					)}
					{state.mode === ModalDisplayMode.Read && state.record && (
						<RecognitionViewModal
							recognition={state.record}
						/>
					)}
					{state.mode === ModalDisplayMode.Update && state.record && (
						<RecognitionModal
							mode='edit'
							recognition={state.record}
							employee={state.record.employee} />
					)}
					{state.mode === ModalDisplayMode.Delete && (
						<SkeDialogDelete
							onCancel={handleCancelDelete}
							onConfirm={handleConfirmDelete}
							successMessage='Recognition deleted'
							message='Are you sure you want to delete this?'
						/>
					)}
					{/*intentionally not hiding when not in print mode due to race condition*/}
					{state.mode === ModalDisplayMode.Print && state.record && (
						<PDFDownloadLink
							document={<RecognitionCertificateContent
								recognition={state.record} />}
							fileName={`${state.record?.employee.firstName} ${state.record?.employee.lastName} - recognition from ${state.record?.date}`}
						>
							{({blob, url, loading, error}) => {
								if (state.mode === ModalDisplayMode.Print && !loading && !error && !!blob) {
									setTimeout(() => {
										downloadButtonRef.current?.click();
									}, 0);
								}
								return loading ? null :
									<button
										type="button"
										className="d-none"
										onClick={() => dispatch(recognitionModalSlice.actions.hide())}
										ref={downloadButtonRef} />;
							}
							}
						</PDFDownloadLink>
					)}
				</>
			)}
		</>
	);
}
