// 2023-06-09
export const SHORT_DATE = 'YYYY-MM-DD';
// 2023-06-09 - doesn't stick with standard format
export const SHORT_DATE_FOR_DATEPICKER = 'yyyy-MM-dd';
// January '23
export const MONTH_NAME_WITH_SHORT_YEAR = `MMMM 'YY`;
// 2023-01
export const YEAR_MONTH_SHORT = 'YYYY-MM';
// 3/5/23 for momentjs
export const US_FRIENDLY_DATE = 'M/D/YY';
// 7:51 AM
export const TIME_HOUR_MIN_AM_PM = 'h:mm A';


export enum DateFormatUsingDateFns {
	// "7/4" for July 4th
	MonthDayNoLeadingZero = 'M/d',
	// "Tue 12/26" for Tuesday, December 26th or Wed 1/3 for Wednesday, January 3rd
	DayNameWithMonthDayNoLeadingZero = 'EEE M/d',
	// "06/01" - this is "MM/DD" from Postgres date handling [done so as to remove year as that makes it more
	// sensitive as PII]
	MonthDayWithLeadingZero = 'MM/dd',
	// "Tuesday" - full day of week name
	DayOfWeekFullName = 'EEEE',
	// "2023-05-03" - using database date format... "What's the perfect date?" - in descending order so items are always
	// sorted chronologically, no confusing order
	PerfectDate = 'yyyy-MM-dd',
	// "2024-05" for May 3rd 2024
	FourYearTwoMonth = 'yyyy-MM',
	// "Jun 04" for June 4th 2024
	ThreeMonthTwoDay = 'MMM dd'
}
