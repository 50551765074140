import { all } from 'redux-saga/effects';
import { combineReducers } from 'redux';
import * as auth from '../../app/modules/auth';
import * as search from '../../app/pages/advanced_search/redux/SearchRedux';
import { disciplineStepSlice } from '../../features/discipline/discipline-step.api';
import { recognitionSlice } from '../../features/recognition/recognition.api';
import { recognitionModalSlice } from '../../features/recognition/recognition-modal.slice';
import { teamSlice } from '../../features/team/team.api';
import { attendanceRuleSlice } from '../../features/attendance/attendance-rule.api';
import { attendanceReasonsSlice } from '../../features/attendance/attendance-reasons.api';
import { coachingCategorySlice } from '../../features/coaching/coaching-categories.api';
import { activitySlice } from '../../features/activity/activity.api';
import { recognitionCategoriesSlice } from '../../features/recognition/recognition-categories.api';
import {companySlice} from "../../features/company/company.api";
import {coachingSlice} from '../../features/coaching/coaching.api'
import {coachingModalSlice} from '../../features/coaching/coaching-modal.slice'
import {attendanceSlice} from '../../features/attendance/attendance.api'
import {attendanceModalSlice} from '../../features/attendance/attendance-modal.slice';
import {disciplineSlice} from '../../features/discipline/discipline.api';
import {disciplineModalSlice} from '../../features/discipline/discipline-modal.slice';


export const rootReducer = combineReducers({
	auth: auth.reducer,
	search: search.reducer,
	[teamSlice.reducerPath]: teamSlice.reducer,
	[attendanceModalSlice.name]: attendanceModalSlice.reducer,
	[attendanceSlice.reducerPath]: attendanceSlice.reducer,
	[coachingModalSlice.name]: coachingModalSlice.reducer,
	[coachingSlice.reducerPath]: coachingSlice.reducer,
	[companySlice.reducerPath]: companySlice.reducer,
	[disciplineModalSlice.name]: disciplineModalSlice.reducer,
	[disciplineSlice.reducerPath]: disciplineSlice.reducer,
	[disciplineStepSlice.reducerPath]: disciplineStepSlice.reducer,
	[attendanceRuleSlice.reducerPath]: attendanceRuleSlice.reducer,
	[attendanceReasonsSlice.reducerPath]: attendanceReasonsSlice.reducer,
	[coachingCategorySlice.reducerPath]: coachingCategorySlice.reducer,
	[activitySlice.reducerPath]: activitySlice.reducer,
	[recognitionCategoriesSlice.reducerPath]: recognitionCategoriesSlice.reducer,
	[recognitionSlice.reducerPath]: recognitionSlice.reducer,
	[recognitionModalSlice.name]: recognitionModalSlice.reducer,
});

export type RootState = ReturnType<typeof rootReducer>

export function* rootSaga() {
	yield all([auth.saga()]);
	yield all([search.saga()]);
}
