import clsx from 'clsx';
import { useEffect, useRef } from 'react';
import ReactToPrint from 'react-to-print';
import { CloseButtonIcon } from '../../../components/icons/CloseButtonIcon';
import { ConnectionTypeEnum } from '../../../types/ConnectionType';
import { Attendance } from '../../attendance/models/Attendance.models';
import { Discipline, DisciplineStatusEnum, DisciplineTextFields } from '../models/Discipline.models';
import { DisciplineStep } from '../models/DisciplineStep.models';
import './CorrectiveActionPrint.scss';
import { User, UserRoleEnum } from '../../user/models/User.model';
import { RootState } from '../../../../setup';
import { shallowEqual, useSelector } from 'react-redux';

export interface Props {
	discipline: Discipline;
	disciplineSteps: DisciplineStep[];
	// TODO: stubbed in to get around async slowness setting textFields on the discipline object after loading
	textFields: DisciplineTextFields;
	correctiveActionModalId: string;
	onClose: () => void;
}

export function CorrectiveActionPrint(
	{
		discipline,
		disciplineSteps,
		textFields,
		correctiveActionModalId,
		onClose,
	}: Props) {
	const pdfCardRef = useRef<HTMLDivElement>(null);
	const backButtonRef = useRef<HTMLButtonElement>(null);
	const user: User = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as User;

	return (
		<>
			<div
				onClick={() => {
					backButtonRef.current?.click();
					onClose();
				}}
				className="modal fade"
				data-bs-backdrop="static"
				data-bs-focus="false"
				id="corrective-action-print-modal">
				<div className="modal-dialog modal-lg modal-dialog-centered modal-fullscreen-md-down modal-dialog-scrollable">
					<div className="modal-content">
						<div className="modal-header justify-content-between">
							<button
								ref={backButtonRef}
								className="btn close_button"
								data-bs-toggle="modal"
								data-bs-target={`#${correctiveActionModalId}`}
							>
								<CloseButtonIcon />
							</button>
							{(
								user.roles.includes(UserRoleEnum.SuperAdmin) ||
								![
									DisciplineStatusEnum.Draft,
									DisciplineStatusEnum.RequestedChange,
									DisciplineStatusEnum.DisciplineDeclined
								].includes(discipline.status)
							) && (
								<ReactToPrint
									content={() => pdfCardRef.current}
									trigger={() => <button className="btn btn-primary">Print</button>}
								/>
							)}
						</div>

						<div
							ref={pdfCardRef}
							className="modal-body p-5">
							<div
								id="corrective-action-print-area"
								className=" mx-0 p-10">
								<div className="d-flex justify-content-center">
									<h1 className="">Corrective Action</h1>
								</div>
								<div>
									<table className="table table-row-bordered border g-3 mb-0">
										<tbody>
											<tr>
												<td>
													<span className="fw-bolder">Employee Name: </span>{discipline.employee.firstName} {discipline.employee.lastName}
												</td>
												<td>
													<span className="fw-bolder">Current Points: </span>{discipline.employee.currentAttendancePoints}
												</td>
												<td>
													<span className="fw-bolder">Date: </span>{discipline.date}
												</td>
											</tr>
											<tr>
												<td>
													<span className="fw-bolder">Job Title: </span>Team Member
												</td>
												<td>
													<span className="fw-bolder">Department: </span>{discipline.team.department}
												</td>
												<td>
													<span className="fw-bolder">Supervisor: </span>{discipline.creator.firstName} {discipline.creator.lastName}
												</td>
											</tr>
											<tr>
											</tr>
										</tbody>
									</table>
								</div>

								<div className="border container mb-3">
									<div className="d-flex justify-content-center">
										<h3>{discipline.step?.type}</h3>
									</div>
									<div className="row">
										<div className="d-flex justify-content-between flex-md-wrap flex-wrap">
											{disciplineSteps.map((step, idx) =>
												<div
													className="chevron-background pb-3"
													key={idx}>
													<div
														className={clsx('chevron-inner', {
															active: discipline.step?.id === step.id,
														})}>
														<span className="warning-step-number d-block fw-boldest fs-5">{step.stepNumber}</span>
														<span className="warning-label">{step.name}</span>
													</div>
												</div>,
											)}
										</div>
									</div>
								</div>

								<p>
									The purpose of this documentation is to bring to your attention expectations
									regarding {discipline?.step?.type}. The intent is to define for you the seriousness of the situation
									so that immediate corrective action is taken.
								</p>

								<span className="fw-bolder">Details of the Incident</span>
								<p>
									{discipline.textFields.incidentDetails ? discipline.textFields.incidentDetails : textFields.incidentDetails}
								</p>

								<span className="fw-bolder">Expectations for Improvement</span>
								<p>
									{discipline.textFields.expectations ? discipline.textFields.expectations : textFields.expectations}
								</p>

								<span className="fw-bolder">If this behavior continues</span>
								<p>
									{discipline.textFields.consequences ? discipline.textFields.consequences : textFields.consequences}
								</p>

								<div className="row">
									<p className="fw-bolder">Past Infractions</p>

									<ul className="list-unstyled previous-disciplines">
										{(discipline.infractions as unknown as (Discipline | Attendance)[])?.map((infraction, idx) => {
											switch (infraction.connectionType) {
												case ConnectionTypeEnum.Discipline:
													return (
														<li
															key={idx}>
															<p className="infraction d-inline-block pe-2 mb-1 badge-light-danger">{infraction.date}:</p>
															<span className="fw-bold">{(infraction as Discipline).step.name}</span>
														</li>
													);
												case ConnectionTypeEnum.Attendance:
													return (
														<li
															key={idx}>
															<p className="infraction d-inline-block pe-2 mb-1 badge-light-warning text-muted">{infraction.date}:</p>
															<span className="fw-bold">{(infraction as Attendance).pointsAssigned} point(s)</span>
															<span className="ms-3 row">
																{(infraction as Attendance).occurrence}
															</span>
														</li>
													);
												default:
													return null;
											}
										})}
									</ul>

								</div>


								<p>
									The above has been discussed with me by my supervisor. I understand the contents and acknowledge and
									understand the corrective action required. I also acknowledge and understand the potential
									consequences
									of noncompliance.
								</p>
								<b>Signatures: </b>
								<table className="table table-row-bordered border g-3">
									<tbody>
										<tr>
											<td>
												Employee:
											</td>
											<td>
												Date:
											</td>
										</tr>
										<tr>
											<td>
												Supervisor:
											</td>
											<td>
												Date:
											</td>
										</tr>
										<tr>
											<td>
												Manager (if applicable):
											</td>
											<td>
												Date:
											</td>
										</tr>
										<tr>
											<td>
												HR:
											</td>
											<td>
												Date:
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
