import moment from 'moment';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import { PageTitle } from '../../../_library/layout/core';
import { teamSlice } from '../../../features/team/team.api';
import rocket from '../../../images/dashboard/rocket.svg';
import { RootState } from '../../../setup';
import { getTeamKpi } from '../../CRUD/TeamCRUD';
import { UserModel } from '../../models';
import { RecentConnectionsWidget } from './RecentConnectionsWidget';
import { RequiredWidget } from './RequiredWidget';
import { RiskWidget } from './RiskWidget';
import './SupervisorDashboard.scss';
import { SupervisorStaticWidget } from './SupervisorStaticWidget';

function SupervisorDashboardContent() {
	const token: string = useSelector<RootState>(({ auth }) => auth.accessToken, shallowEqual) as string;
	const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel;
	const [recognitionCount, setRecognitionCount] = useState<number>(0);
	const [attendanceCount, setAttendanceCount] = useState<number>(0);
	const [engagementRate, setEngagementRate] = useState<number>(0);
	const navigate = useNavigate();
	const { data: teams, isSuccess: teamsSuccess } = teamSlice.useGetAllTeamsQuery();

	useEffect(() => {
		let abort = new AbortController();

		return () => {
			abort.abort();
		};
	}, [token]);

	useEffect(() => {
		const startDate = moment().subtract(1, 'months').format('YYYY-MM-DD');
		const endDate = moment().add(1, 'day').format('YYYY-MM-DD');

		if (teamsSuccess && teams &&  teams.length > 0) {
			(async () => {
				let rCount = 0;
				let aCount = 0;
				let eRate = 0;
				for (let i = 0; i < teams.length; i++) {
					try {
						const response = await getTeamKpi(teams[i]?.id, startDate, endDate, token);
						const { numberOfRecognitions, numberOfAttendances, engagementRate } = response.data;
						rCount += +numberOfRecognitions;
						aCount += +numberOfAttendances;
						eRate += isNaN(engagementRate) ? 0 : parseFloat(engagementRate);
					} catch (error) {
						console.log('Bad request');
					}
				}
				setRecognitionCount(rCount);
				setAttendanceCount(aCount);
				setEngagementRate(eRate / teams.length);
			})();
		}
	}, [teams, token, user]);


	return (
		<>
			<div className="row">
				<div className="col-lg-8 col-md-12 col-sm-12">
					<div className="row">
						<div className="row pt-5 pe-2">
							<RequiredWidget className="mb-5" />
						</div>
						<div className="col-lg-6 col-md-12 col-sm-12 d-flex flex-column just">
							<SupervisorStaticWidget
								className="mb-2 hoverable"
								title="Recognition"
								onClick={() => navigate("/connections/recognition")}
								metric={recognitionCount}
								description="How often are you recognizing your team?"
								path={rocket}
								bgColor="primary"
							/>
							<SupervisorStaticWidget
								className="mb-2 hoverable"
								title="Attendance"
								bgColor='#FFCACC'
								textColor='darkslategray'
								onClick={() => navigate("/connections/attendance")}
								metric={attendanceCount}
								description="How available is your workforce?"
								path="/"
							/>
							<SupervisorStaticWidget
								className="mb-2"
								title="Engagement Rate"
								metric={`${engagementRate?.toFixed(1)}%`}
								description="How are you balancing performance for you entire team?"
								path={rocket}
								bgColor="primary"
							/>
						</div>
						<div className="col-lg-6 col-md-12 col-sm-12">
							<RiskWidget
								pageLimit={3}
								className="" />
						</div>
					</div>

				</div>
				<div className="col-lg-4 col-md-12 col-sm-12">
					<RecentConnectionsWidget className="" />
				</div>
			</div>
		</>
	);
}

export function SupervisorDashboard() {
	return (
		<>
			<PageTitle breadcrumbs={[]}>Dashboard</PageTitle>
			<SupervisorDashboardContent />
		</>
	);
}
