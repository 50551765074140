// IAdvancedConnectionSearchResult from api codebase
import { ConnectionTypeEnum } from '../../../app/types/ConnectionType';

export enum ConnectionTypeEnumWithCa {
	Attendance = 'ATTENDANCE',
	Coaching = 'COACHING',
	Recognition = 'RECOGNITION',
	Discipline = 'DISCIPLINE',
	Excused = 'EXCUSED',
	CorrectiveAction = 'CORRECTIVE ACTION',
}

export interface Connection {
	category_name: string | null;
	conn_count: number | null;
	connection_type: ConnectionTypeEnumWithCa;
	created_at: Date | null;
	creator_id: number | null;
	creator_name: string | null;
	date: Date | null;
	employee_id: number | null;
	employee_name: string | null;
	full_count: number | null;
	group_id: string | null;
	id: number;
	status: string | null;
	step_name: string | null;
	team_id: number | null;
	team_name: string | null;
	tenant_id: string | null;
}

// subset of IAdvancedConnectionSearchResult as it's a curated whitelist of allowed fields
export type ConnectionSortableFields =
	'connection_type' |
	'date' |
	'employee_name' |
	'creator_name' |
	'team_name' |
	'category_name' |
	'status' |
	'step_name';

// IAdvancedConnectionSearchParams from api codebase
export type ConnectionFilterableFields =
	'category_name' |
	// remapped to `connection_type_search` on the api
	'connection_type' |
	'creator_name' |
	'employee_name' |
	'end_date' |
	'start_date' |
	'before_date' |
	'after_date' |
	'date' |
	'status' |
	'step_name' |
	'team_name';
